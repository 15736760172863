/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Collapse, Empty, Modal, Row, Spin, Table } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState, useCallback } from "react";
import { localhost } from "../../server";
import BoxIcon from "./../../images/BoxInforImage.svg";

import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
} from "@ant-design/icons";
import ReactDragListView from "react-drag-listview";
import axios from "axios";
import LoadingIcon from "./../../images/iconLoading.svg";
import { SwiperSlide, Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination } from "swiper/modules";
import { authAxios } from "../../api/axiosClient";

const pdfjsLib = window.pdfjsLib;
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js";

function ModalDetail({ open, close, dataRecord }) {
  const [itemsClone, setItemsClone] = useState([]);
  const [dataDetail, setDataDetail] = useState();
  const [loadingImage, setLoadingImage] = useState(true);
  const [mainImageURL, setMainImageURL] = useState();
  const [thumbnailURL, setThumbnailURL] = useState([]);
  const [indexImage, setIndexImage] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const inforUser = JSON.parse(sessionStorage.getItem("info_user"));

  const labelCollapse = (text) => {
    return (
      <>
        <span style={{ columnGap: 8 }}>
          <img alt="" src={BoxIcon}></img>
          {text}
        </span>
      </>
    );
  };

  const textNG = (text, record) => {
    if (record["check_result"] === "✖") {
      return <span style={{ color: "red" }}>{text}</span>;
    } else {
      return <span>{text}</span>;
    }
  };

  const [columns, setColumns] = useState([
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      ellipsis: true,
      align: "left",
      resizable: true, // Enable resizing
      render: (text, record) => textNG(text, record),
    },
    // {
    //   title: '項目名',
    //   dataIndex: '項目名',
    //   key: '項目名',
    //   // width: 150,
    //   ellipsis: true,
    //   align: "left",
    //   render: (text, record) => textNG(text, record)
    // },
    {
      title: "JP Field name",
      dataIndex: "jp_field_name",
      key: "jp_field_name",
      // width: 150,
      ellipsis: true,
      align: "left",
      render: (text, record) => textNG(text, record),
    },
    {
      title: "EN Field name",
      dataIndex: "en_field_name",
      key: "en_field_name",
      // width: 150,
      ellipsis: true,
      align: "left",
      render: (text, record) => textNG(text, record),
    },
    {
      title: "Required Value",
      dataIndex: "required_value",
      key: "required_value",
      // width: 130,
      ellipsis: true,
      align: "left",
      render: (text, record) => textNG(text, record),
    },
    {
      title: (
        <span>
          Checksheet<br></br> OCR value
        </span>
      ),
      dataIndex: "checksheet_ocr_value",
      key: "checksheet_ocr_value",
      // width: 140,
      align: "left",
      ellipsis: true,
      render: (text, record) => textNG(text, record),
    },
    {
      title: (
        <span>
          Name plate/ <br></br>Image OCR value
        </span>
      ),
      dataIndex: "nameplate_ocr_value",
      key: "nameplate_ocr_value",
      // width: 100,
      align: "left",
      ellipsis: true,
      render: (text, record) => textNG(text, record),
    },
    {
      title: "Check result",
      dataIndex: "check_result",
      key: "check_result",
      // width: 100,
      align: "left",
      ellipsis: true,
      render: (text, record) =>
        text === "✖" ? (
          <CloseOutlined style={{ color: "red", fontSize: 16 }} />
        ) : text === "✔" ? (
          <CheckOutlined style={{ color: "green", fontSize: 16 }} />
        ) : (
          text
        ),
    },
    // {
    //   title: 'Remark',
    //   dataIndex: 'Remark',
    //   key: 'Remark',
    //   // width: 200,
    //   ellipsis: true,
    //   align: "left",
    //   render: (text, record) => textNG(text, record)
    // },
  ]);

  const fetchDataDetail = () => {
    setLoadingTable(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("pack_id", dataRecord.pack_id);
    data.append("pack_id_model", dataRecord.pack_id_model);
    data.append("tb_content", dataRecord.tb_content);
    data.append("user_role", inforUser.user_role);
    authAxios()
      .post(`${localhost}/manager_details`, data)
      .then((res) => {
        setLoadingTable(false);
        setDataDetail(res.data);
        let arrData = [];
        for (let i = 0; i < res.data.lst_result.length; i++) {
          arrData.push({
            key: i + 1,
            label: labelCollapse(res.data.lst_result[i].sheet_name),
            children: (
              <DataTable
                columns={columns}
                dataSource={res.data.lst_result[i].values}
                setColumns={setColumns}
              />
            ),
          });
        }

        // setDataSource(res.data.results[0].data_sheet)
        // setUrlPDF(res.data.data_img)
        setItemsClone(arrData);
      })
      .catch((err) => {
        setLoadingTable(false);
        console.log(err);
      });
  };

  const fetchListImage = (index) => {
    setLoadingImage(true);
    authAxios()
      .post(
        `${localhost}/file_details`,
        {
          pack_file_path:
            dataRecord.pack_list_file_path.length > 0
              ? dataRecord.pack_list_file_path[0][index]
              : [],
          pack_list_thumbnail_path:
            dataRecord.pack_list_thumbnail_path.length > 0
              ? dataRecord.pack_list_thumbnail_path[0]
              : [],
          user_role: inforUser.user_role
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoadingImage(false);
        convertToImage(res.data);
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };

  const changeMainImage = (index) => {
    setIndexImage(index);
    if (index !== indexImage) {
      fetchListImage(index);
    }
  };

  const convertToImage = (value) => {
    let arrData = [];
    for (let i = 0; i < value.lst_thum_base64.length; i++) {
      arrData.push(`data:image/jpeg;base64,${value.lst_thum_base64[i]}`);
    }
    setThumbnailURL(arrData);
    setMainImageURL(`data:image/jpeg;base64,${value.img_base64}`);
    setLoadingImage(false);
  };

  useEffect(() => {
    fetchDataDetail();
    fetchListImage(indexImage);
  }, []);

  const onChange = (key) => {
  };

  const antIcon = <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />;

  const textResult = (text) => {
    if (text === "-1") {
      return "Đang xử lí";
    } else if (text === "0") {
      return "Qualified";
    } else {
      return "Not Qualified";
    }
  };
  return (
    <>
      <Modal
        open={open}
        // onOk={handleOk}
        onCancel={close}
        width={"100%"}
        style={{ height: "60svh", top: 10 }}
        // closable={false}
        className="modal-detail-lc"
        footer={false}
      // centered
      >
        {dataDetail !== undefined ? (
          <Row>
            <Col
              span={8}
              style={{
                height: "100%",
                width: "100%",
                // justifyContent: 'center',
                // alignItems: 'center'
              }}
            >
              <div
                style={{ position: "relative", paddingTop: "0.6%" }}
                className="size-image"
              >
                {/* {mainImageURL && thumbnailURL.length > 0 ?
                  <>
                    <Button onClick={nextImage} disabled={lockBtnNextPage} className='btn-next-image'><RightOutlined style={{ fontSize: 25, color: "pray" }} /></Button>
                    <Button onClick={previousImage} disabled={lockBtnPreviousPage} className='btn-previous-image'><LeftOutlined style={{ fontSize: 25, color: "pray" }} /></Button>
                  </>
                  : null} */}
                {loadingImage === false ? (
                  <TransformWrapper initialScale={1}>
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <>
                        <Button
                          id="reset-zoom"
                          onClick={() => resetTransform()}
                          style={{ display: "none" }}
                        ></Button>
                        <TransformComponent
                          contentStyle={{
                            cursor: "zoom-in",
                            width: "100%",
                            display: "flex",
                            height: "78vh",
                            justifyContent: "center",
                          }}
                        >
                          <>
                            <img
                              src={mainImageURL}
                              style={{
                                width: "94%",
                                height: "76vh",
                                filter: "drop-shadow(2px 4px 6px black)",
                                imageRendering: "unset",
                              }}
                              alt="Hình ảnh"
                            />
                          </>
                        </TransformComponent>
                      </>
                    )}
                  </TransformWrapper>
                ) : !loadingTable ? (
                  <div
                    style={{
                      display: "flex",
                      height: "78vh",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ width: "7%" }}
                      src={LoadingIcon}
                      className="load-image-desktop"
                      alt=""
                    ></img>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "74vh",
                      padding: "1% 1% 2%",
                    }}
                  >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </div>
              <div className="thumbnail-class-desktop">
                <Swiper
                  slidesPerView={window.screen.width * 0.0035}
                  // spaceBetween={50}
                  pagination={{
                    clickable: true,
                  }}
                  centerInsufficientSlides={true}
                  modules={[Pagination]}
                  style={{ width: "80%" }}
                  className="mySwiper"
                >
                  {thumbnailURL.map((item, index) => (
                    <SwiperSlide style={{ height: "11.5vh" }}>
                      {" "}
                      <img
                        onClick={() => changeMainImage(index)}
                        style={{
                          border: index === indexImage ? "2px solid red" : null,
                        }}
                        src={item}
                        alt=""
                      ></img>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </Col>
            <Col span={16} style={{ paddingLeft: "2%" }}>
              {/* <ReactDragListView.DragColumn {...dragProps}>
                <Table
                  size='small'
                  dataSource={dataSource}
                  columns={columnsWithResize}
                  components={{
                    header: {
                      cell: ResizableTitle,
                    },
                  }}
                  scroll={{
                    x: 1100
                  }}
                  pagination={false}
                  bordered
                  className='table-detail-product'
                />
              </ReactDragListView.DragColumn> */}
              {/* <DataTable columns={columns} dataSource={dataSource} setColumns={setColumns} /> */}
              <Row style={{ rowGap: "1ch", paddingBottom: "1%" }}>
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>Sent date: </span>
                  {dayjs(dataRecord.sent_date).format("DD-MM-YYYY")}
                </Col>
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>Checked date: </span>
                  {dayjs(dataRecord.checked_date).format("DD-MM-YYYY")}
                </Col>
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>Model name: </span>
                  {dataRecord.value_model_name}
                </Col>
                <Col span={6}>
                  <span style={{ fontWeight: "bold" }}>Result: </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        dataRecord.pack_status === "0"
                          ? "green"
                          : dataRecord.pack_status === "1"
                            ? "#E55353"
                            : "black",
                    }}
                  >
                    {textResult(dataRecord.pack_status)}
                  </span>
                </Col>
              </Row>

              <Collapse
                style={{
                  height: "88vh",
                  overflowY: "auto",
                  background: "#fff",
                  border: "unset",
                }}
                expandIconPosition="end"
                size="small"
                items={itemsClone}
                defaultActiveKey={["1"]}
                onChange={onChange}
              />
            </Col>
          </Row>
        ) : (
          <Row
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin indicator={antIcon} spinning={true} size="large"></Spin>
          </Row>
        )}
      </Modal>
    </>
  );
}

const DataTable = ({ columns, dataSource, setColumns }) => {
  const dragProps = {
    onDragEnd: useCallback(
      (fromIndex, toIndex) => {
        const updatedColumns = [...columns];
        const item = updatedColumns.splice(fromIndex, 1)[0];
        updatedColumns.splice(toIndex, 0, item);
        setColumns(updatedColumns);
      },
      [columns]
    ),
    nodeSelector: "th",
    handleSelector: ".dragHandler",
    ignoreSelector: "react-resizable-handle",
  };
  return (
    <>
      <ReactDragListView.DragColumn {...dragProps}>
        <Table
          scroll={{
            y: "40vh",
          }}
          size="small"
          dataSource={dataSource}
          columns={columns}
          // components={{
          //   header: {
          //     cell: ResizableTitle,
          //   },
          // }}
          pagination={false}
          bordered
          className="table-detail-product"
        />
      </ReactDragListView.DragColumn>
    </>
  );
};

export default ModalDetail;
