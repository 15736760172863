import React, { useEffect, useState } from "react";
import "./ModalFileManager.css";
import { Button, Col, Form, Row, Pagination } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { localhost } from "../../../server";
import language from "../../../language.json";
import PriorityIcon from "../../../images/file_manager/PriorityIcon.svg";
import ModalFilterData from "./modal/ModalFilterData";
import iconSearch from "../../../images/file_manager/iconSearch.svg";
import IconStatusQualified from "../../../images/file_manager/IconStatusQualified.svg";
import IconStatusNotQualified from "../../../images/file_manager/IconStatusNotQualified.svg";
import IconStatusImgNotGood from "../../../images/file_manager/IconStatusImgNotGood.svg";
import IconStatusProcessing from "../../../images/file_manager/IconStatusProcessing.svg";
import IconPumbType from "../../../images/file_manager/IconPumbType.svg";
import IconLocation from "../../../images/file_manager/IconLocation.svg";
import IconNameOrder from "../../../images/file_manager/IconNameOrder.svg";
import NoDataIcon from "../../../images/file_manager/NoDataIcon.svg";
import IconDeleteFilter from "../../../images/file_manager/IconDeleteFilter.svg";
import LoadingIcon from "../../../images/iconLoading.svg";
import IconTotalFile from "../../../images/file_manager/IconTotalFile.svg";

import ModalViewDetail from "./modal/ModalViewDetail";
import { authAxios } from "../../../api/axiosClient";

const FileManager = ({
  chooseLanguage,
  setListStatus,
  dataNotification,
  setDataNotification,
  setCheckNoti,
  setCountNotification
}) => {
  const inforUser = JSON.parse(sessionStorage.getItem("info_user"));

  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState(dataNotification);
  const [dataDetail, setDataDetail] = useState();
  const [pager, setPager] = useState({
    pageSize: 5,
    count: 0,
    current: 1,
  });
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [rotatePhone, setRotatePhone] = useState(false);
  const [fieldFilter, setFieldFilter] = useState({
    id_user: inforUser.user_id,
    upload_date: dayjs().format("YYYY-MM-DD"),
    page_index: 1,
    page_size: pager.pageSize,

    input_search: "",
    pump_id: "",
    is_search: "",
    pack_status: "",

    pack_id: "",
    tb_package: "",
  });

  const [loadingPage, setLoadingPage] = useState(true);

  const functionSetData = (date) => {
    return {
      id_user: inforUser.user_id,
      upload_date: date,
      page_index: 1,
      page_size: pager.pageSize,

      input_search: "",
      pump_id: "",
      is_search: "",
      pack_status: "",

      pack_id: "",
      tb_package: "",
    }
  }

  const textStatus = (text, data) => {
    if (parseInt(data.is_checksheet) === 0) {
      if (data.lst_data_multi.length === 0) {
        return (
          <span className="text-check-status" style={{ background: "#64748B" }}>
            <img src={IconStatusProcessing} alt=""></img>{" "}
            {language[chooseLanguage].processing}
          </span>
        );
      }
      else if (parseInt(data.total_qa) > 0) {
        return (
          <span className="text-check-status" style={{ background: "#DAA400" }}>
            <img src={IconStatusImgNotGood} alt=""></img> {language[chooseLanguage].images_not_good}
          </span>
        );
      } else if (parseInt(data.total_notqualified) > 0) {
        return (
          <span className="text-check-status" style={{ background: "#C63F3F" }}>
            <img src={IconStatusNotQualified} alt=""></img>{" "}
            {language[chooseLanguage].not_qualified}
          </span>
        );
      } else {
        return (
          <span className="text-check-status" style={{ background: "#07864B" }}>
            <img src={IconStatusQualified} alt=""></img>{" "}
            {language[chooseLanguage].qualified}
          </span>
        );
      }
    } else {
      if (text.toString() === "2") {
        return (
          <span className="text-check-status" style={{ background: "#C63F3F" }}>
            <img src={IconStatusNotQualified} alt=""></img>{" "}
            {language[chooseLanguage].not_qualified}
          </span>
        );
      } else if (text.toString() === "1") {
        return (
          <span className="text-check-status" style={{ background: "#07864B" }}>
            <img src={IconStatusQualified} alt=""></img>{" "}
            {language[chooseLanguage].qualified}
          </span>
        );
      } else if (text.toString() === "0") {
        return (
          <span className="text-check-status" style={{ background: "#64748B" }}>
            <img src={IconStatusProcessing} alt=""></img>{" "}
            {language[chooseLanguage].processing}
          </span>
        );
      } else {
        return (
          <span className="text-check-status" style={{ background: "#DAA400" }}>
            <img src={IconStatusImgNotGood} alt=""></img> {language[chooseLanguage].images_not_good}
          </span>
        );
      }
    }
  };

  const fetchListData = (params) => {
    const FormData = require("form-data");
    let data = new FormData();
    data.append("id_user", params.id_user);
    data.append("page_index", params.page_index);
    data.append("page_size", params.page_size);
    data.append("upload_date", params.upload_date);

    data.append("input_search", params.input_search);
    data.append("pump_id", params.pump_id);
    data.append("is_search", params.is_search);
    data.append("pack_status", params.pack_status);
    data.append("user_role", inforUser.user_role);

    data.append("pack_id", "");
    data.append("tb_package", "");

    authAxios()
      .post(`${localhost}/list_file`, data)
      .then((res) => {
        setPager({
          current: params.page_index,
          pageSize: params.page_size,
          count: res.data.list_count,
        });
        setDataSource(res.data.list_file);
        setListStatus(res.data.list_status[0]);
        setLoadingPage(false);
      })
      .catch((err) => {
        setLoadingPage(false);
      });
  };

  useEffect(() => {
    if (window.orientation === 90 || window.orientation === -90) {
      setRotatePhone(true);
    } else {
      setRotatePhone(false);
    }

    if (dataNotification.length === 0) {
      setLoadingPage(true);
      const id = setInterval(() => {

        fetchListData(functionSetData(dayjs().format("YYYY-MM-DD")));

        clearInterval(id);
      }, 300);
      return () => clearInterval(id);
    } else {
      setPager((prev) => ({ ...prev, count: 1 }));
      setLoadingPage(false);
    }
  }, []);

  const handleChangePagination = (page, pageSize) => {
    fetchListData({
      ...fieldFilter,
      page_index: page,
      page_size: pageSize,
    });
    setFieldFilter({
      ...fieldFilter,
      page_index: page,
      page_size: pageSize,
    });
  };

  const UpdateCusStatus = async (value, type) => {
    if (type !== value.cus_status) {
      const FormData = require("form-data");
      let data = new FormData();
      data.append("id_user", inforUser.user_id);
      data.append("tb_package", value.tb_package);
      data.append("vl_status", type);
      data.append("pack_id", value.pack_id);
      data.append("user_role", inforUser.user_role);

      await authAxios()
        .post(`${localhost}/update_cus_status`, data)
        .then((res) => {
          fetchCountNotification()
          fetchListData(fieldFilter);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchCountNotification = async () => {
    const FormData = require("form-data");
    let data = new FormData();

    data.append("id_user", inforUser.user_id);
    data.append("user_role", inforUser.user_role);
    data.append("date_now", dayjs().format("YYYY-MM-DD"));

    await authAxios()
      .post(`${localhost}/count_notifi`, data)
      .then((res) => {
        // sessionStorage.setItem("openNotification", false)
        setCheckNoti(false);
        setCountNotification(res.data.total_notif);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const textCusStatus = (cusStatus, packStatus, data) => {
    if(parseInt(data.total_pump) / parseInt(data.total_files) === 1) {
      return language[chooseLanguage].completed;
    }
    else if (cusStatus === "-1") {
      if (packStatus === "1") {
        return language[chooseLanguage].completed;
      }
    } else if (cusStatus === "1") {
      return language[chooseLanguage].verified + " - " + language[chooseLanguage].ok;
    }
    if (cusStatus === "0") {
      return language[chooseLanguage].verified + " - " + language[chooseLanguage].not_ok;
    }
    return "";
  };

  const showModalDetail = (record) => {
    setDataDetail(record);
    setOpenModalDetail(true);
  };

  const clearFilter = () => {
    form.resetFields();
    setDataNotification([]);
    form.setFieldsValue({
      date: dayjs(),
    });

    setFieldFilter(functionSetData(dayjs().format("YYYY-MM-DD")));

    fetchListData(functionSetData(""));
  };

  useEffect(() => {
    function handleOrientationChange() {
      if (window.orientation === 90 || window.orientation === -90) {
        setRotatePhone(true);
      } else {
        setRotatePhone(false);
      }
    }

    // Thêm event listener khi component được mount
    window.addEventListener("orientationchange", handleOrientationChange);

    // Loại bỏ event listener khi component unmount
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  return (
    <>
      <div
        className="container-file-manager"
        style={{ height: rotatePhone === true ? "110svh" : "81svh" }}
      >
        <div style={{ padding: "0% 5%" }}>
          <Row style={{ paddingTop: "2%" }}>
            <Col span={16} style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  color: "#394B76",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textTransform: "uppercase",
                }}
              >
                {language[chooseLanguage].file_manager}
              </span>
            </Col>
            <Col
              span={8}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                columnGap: "1ch",
              }}
            >
              <Button
                style={{ padding: "4px" }}
                onClick={() => setOpenModalFilter(true)}
              >
                <img src={iconSearch} alt=""></img>
              </Button>
              <Button style={{ padding: "4px" }} onClick={clearFilter}>
                <img src={IconDeleteFilter} alt=""></img>
              </Button>
            </Col>
          </Row>

          {loadingPage === false ? (
            dataSource.length > 0 ? (
              <>
                <div className="container-list-package">
                  {dataSource.map((item, index) => (
                    <Row className="bg-thumbnail-list-file" key={index}>
                      <Row style={{ width: "100%" }}>
                        <Col span={8} style={{ position: "relative" }}>
                          <img
                            src={`data:image/jpeg;base64,${item.thumb_base64}`}
                            onClick={() => showModalDetail(item)}
                            alt=""
                            className="img-list-package"
                          ></img>
                        </Col>
                        <Col
                          span={16}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "4% 2%",
                          }}
                        >
                          <div className="list-thumbnail-manager">
                            <Row
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col
                                span={13}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  className="title-thumbnail-manager"
                                  onClick={() => showModalDetail(item)}
                                >
                                  {item.vl_mfg_no.toUpperCase()} &nbsp;
                                </span>
                                <span>
                                  {item.prioriti === "1" ? (
                                    <img src={PriorityIcon} alt=""></img>
                                  ) : null}
                                </span>
                              </Col>

                              <Col
                                span={11}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <span className="text-cus-status">
                                  {textCusStatus(
                                    item.cus_status,
                                    item.pack_status,
                                    item
                                  )}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <span
                                  style={{
                                    paddingLeft: "1.5%",
                                    color: "#64748B",
                                    fontWeight: 400,
                                  }}
                                >
                                  {dayjs(item.upload_date).format("HH:mm:ss")}
                                </span>
                              </Col>
                              <Col span={15}>
                                {textStatus(item.check_status, item)}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ width: "100%" }}>
                        <Col
                          span={6}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img src={IconPumbType} alt=""></img>&nbsp;
                          {item.pumb_name}
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img src={IconNameOrder} alt=""></img>&nbsp;
                          {item.vl_model_name.toUpperCase()}
                        </Col>
                        <Col
                          span={6}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <img src={IconLocation} alt=""></img>&nbsp;
                          {item.pump_location}
                        </Col>
                      </Row>

                      {item.pack_status === "1" && item.cus_status === "-1" ? (
                        <Row className="container-check-cusStatus">
                          <Col span={7}>
                            <Button
                              style={{ color: "#394B76" }}
                              onClick={() => UpdateCusStatus(item, "0")}
                            >
                              {language[chooseLanguage].not_ok}
                            </Button>
                          </Col>
                          <Col span={7}>
                            <Button
                              style={{ color: "#fff", background: "#0C4DA2" }}
                              onClick={() => UpdateCusStatus(item, "1")}
                            >
                              {language[chooseLanguage].ok}
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                    </Row>
                  ))}
                </div>
                <div className="pagination-file-manager">
                  <Pagination
                    simple
                    current={pager.current}
                    defaultPageSize={5}
                    total={pager.count}
                    onChange={handleChangePagination}
                    showSizeChanger
                    pageSizeOptions={[5, 10, 15, 20]}
                    locale={{ items_per_page: "" }}
                  />
                  <span>{pager.count}</span>
                  <img src={IconTotalFile} alt=""></img>
                </div>
              </>
            ) : (
              <div className="container-noData-file-manager">
                <div style={{ display: "grid" }}>
                  <img src={NoDataIcon} alt=""></img>
                  <p> {language[chooseLanguage].content_no_data}</p>
                </div>
              </div>
            )
          ) : (
            <div className="container-loading-file-manager">
              <img
                style={{ width: "7%" }}
                src={LoadingIcon}
                className="load-image-desktop"
                alt=""
              ></img>
            </div>
          )}
        </div>

        {openModalFilter === true ? (
          <ModalFilterData
            open={openModalFilter}
            setOpenModalFilter={setOpenModalFilter}
            setFieldFilter={setFieldFilter}
            fetchListData={fetchListData}
            form={form}
            pager={pager}
            chooseLanguage={chooseLanguage}
          />
        ) : null}
        {openModalDetail === true ? (
          <ModalViewDetail
            open={openModalDetail}
            setOpenModalDetail={setOpenModalDetail}
            dataDetail={dataDetail}
            chooseLanguage={chooseLanguage}
            fetchListData={fetchListData}
            fieldFilter={fieldFilter}
            pager={pager}
          />
        ) : null}
      </div>
    </>
  );
};

export default FileManager;
