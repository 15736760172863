import { Col, Row } from "antd";
import React, { useEffect } from "react";
import PriorityIcon from "../../../images/file_manager/PriorityIcon.svg";
import dayjs from "dayjs";
import axios from "axios";
import { localhost } from "../../../server";
import language from "../../../language.json";
import IconStatusQualified from "../../../images/file_manager/IconStatusQualified.svg";
import IconStatusNotQualified from "../../../images/file_manager/IconStatusNotQualified.svg";
import IconStatusImgNotGood from "../../../images/file_manager/IconStatusImgNotGood.svg";
import IconStatusProcessing from "../../../images/file_manager/IconStatusProcessing.svg";
import ArrowBackIcon from "../../../images/arrow/ArrowBack.svg";

import IconPumbType from "../../../images/file_manager/IconPumbType.svg";
import NoDataIcon from "../../../images/file_manager/NoDataIcon.svg";
import { useHistory } from "react-router-dom";
import { authAxios } from "../../../api/axiosClient";

const PageNotification = ({
  setCheckNoti,
  listNotification,
  setDataNotification,
  setListStatus,
  chooseLanguage,
}) => {
  const inforUser = JSON.parse(sessionStorage.getItem("info_user"));
  let history = useHistory();
  const backFileManager = () => {
    setCheckNoti(false);
  };

  const textStatus = (text, data) => {
    if (parseInt(data.is_checksheet) === 0) {
      if (data.lst_data_multi.length === 0) {
        return (
          <span className="text-check-status" style={{ background: "#64748B" }}>
            <img src={IconStatusProcessing} alt=""></img>{" "}
            {language[chooseLanguage].processing}
          </span>
        );
      }
      else if (parseInt(data.total_qa) > 0) {
        return (
          <span className="text-check-status" style={{ background: "#DAA400" }}>
            <img src={IconStatusImgNotGood} alt=""></img> {language[chooseLanguage].images_not_good}
          </span>
        );
      } else if (parseInt(data.total_notqualified) > 0) {
        return (
          <span className="text-check-status" style={{ background: "#C63F3F" }}>
            <img src={IconStatusNotQualified} alt=""></img>{" "}
            {language[chooseLanguage].not_qualified}
          </span>
        );
      } else {
        return (
          <span className="text-check-status" style={{ background: "#07864B" }}>
            <img src={IconStatusQualified} alt=""></img>{" "}
            {language[chooseLanguage].qualified}
          </span>
        );
      }
    } else {
      if (text.toString() === "2") {
        return (
          <span className="text-check-status" style={{ background: "#C63F3F" }}>
            <img src={IconStatusNotQualified} alt=""></img>{" "}
            {language[chooseLanguage].not_qualified}
          </span>
        );
      } else if (text.toString() === "1") {
        return (
          <span className="text-check-status" style={{ background: "#07864B" }}>
            <img src={IconStatusQualified} alt=""></img>{" "}
            {language[chooseLanguage].qualified}
          </span>
        );
      } else if (text.toString() === "0") {
        return (
          <span className="text-check-status" style={{ background: "#64748B" }}>
            <img src={IconStatusProcessing} alt=""></img>{" "}
            {language[chooseLanguage].processing}
          </span>
        );
      } else {
        return (
          <span className="text-check-status" style={{ background: "#DAA400" }}>
            <img src={IconStatusImgNotGood} alt=""></img> {language[chooseLanguage].images_not_good}
          </span>
        );
      }
    }
  };

  const showDetail = (value) => {
    const FormData = require("form-data");
    let data = new FormData();

    data.append("id_user", inforUser.user_id);
    data.append("user_role", inforUser.user_role);
    data.append("page_index", 1);
    data.append("page_size", 5);
    data.append("upload_date", dayjs().format("YYYY-MM-DD"));

    data.append("input_search", "");
    data.append("pump_id", "");
    data.append("is_search", "2");
    data.append("pack_status", "");

    data.append("pack_id", value.pack_id);
    data.append("tb_package", value.tb_package);

    authAxios()
      .post(`${localhost}/list_file`, data)
      .then((res) => {
        setListStatus(res.data.list_status[0]);

        setCheckNoti(false);
        setDataNotification(res.data.list_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const iconCircle = (item) => {
    return (
      <div
        style={{
          width: 8,
          height: 8,
          background: parseInt(item.notified) === 1 ? "#00AAF1" : "#94A3B8",
          borderRadius: "100%",
        }}
      ></div>
    );
  };

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      // Xử lý sự kiện khi người dùng nhấn nút "back" ở đây
      console.log("Nút back được nhấn!");
    });
  }, []);

  return (
    <div style={{ display: "grid", padding: "0% 5%" }}>
      <Row style={{ paddingTop: "2%" }}>
        <Col span={2} onClick={backFileManager}>
          <img src={ArrowBackIcon} alt=""></img>
        </Col>
        <Col
          span={20}
          style={{
            textAlign: "center",
            fontWeight: 800,
            fontSize: 14,
            color: "#25355B",
          }}
        >
          {language[chooseLanguage].notifications}
        </Col>
        <Col span={2}></Col>
      </Row>
      {listNotification.length > 0 ? (
        <>
          <div
            className="container-list-package"
            style={{ maxHeight: "80svh" }}
          >
            {listNotification.map((item) => (
              <>
                <Row
                  onClick={() => showDetail(item)}
                  className="bg-thumbnail-list-file"
                  style={{
                    background:
                      String(item.notified) === "1" ? "aliceBlue" : null,
                  }}
                >
                  <Row style={{ width: "100%" }}>
                    <Col span={8} style={{ position: "relative" }}>
                      <img
                        src={`data:image/jpeg;base64,${item.thumb_base64}`}
                        alt=""
                        className="img-list-package"
                      ></img>
                    </Col>
                    <Col
                      span={15}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "4% 2%",
                      }}
                    >
                      <div className="list-thumbnail-manager">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Col
                            span={24}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>
                              {" "}
                              {item.prioriti === "1" ? (
                                <img src={PriorityIcon} alt=""></img>
                              ) : null}{" "}
                              &nbsp;
                            </span>
                            <span className="title-thumbnail-manager">
                              {item.vl_mfg_no}
                            </span>
                          </Col>
                        </div>
                        <Row
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col
                            span={8}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img src={IconPumbType} alt=""></img>&nbsp;
                            {item.pumb_name}
                          </Col>
                          <Col span={16}>
                            {textStatus(item.check_status, item)}
                            {/* Images not good */}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col span={1} className="centerItems">
                      {iconCircle(item)}
                    </Col>
                  </Row>
                </Row>
              </>
            ))}
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              height: "68svh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "grid" }}>
              <img src={NoDataIcon} alt=""></img>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                  color: "#94A3B8",
                  fontSize: 10,
                }}
              >
                {language[chooseLanguage].content_no_data}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PageNotification;
