import { Form, Input, Table } from 'antd'
import React, { useEffect, useMemo } from 'react'

const TableGridLastCheck = ({
    dataGridLastCheck,
    formGrid,
    listIndexLogicGrid,
    setLoadingTableGrid,
    loadingTableGrid,
    dataPumb,
    setIsCheckLogic,
}) => {
    const listKeyShortcuts = ["!", "@", "#", "$", "%", "^", "&", "*", "(", ")"];

    const columns = [
        {
            title: "#",
            align: "center",
            ellipsis: true,
            width: 40,
            render: (value, item, index) => index + 1,
        },
        {
            title: "MFG　No.（製造番号)",
            dataIndex: "mfg_no",
            key: "mfg_no",
            align: "center",
            ellipsis: true,
            width: 40,
            render: (text, record, index) => formInsert(index, text, 'mfg_no', record)
        },
        {
            title: "Production Instructions",
            dataIndex: "production",
            key: "production",
            align: "center",
            ellipsis: true,
            width: 40,
            render: (text, record, index) => formInsert(index, text, 'production', record)
        },
    ];

    const formInsert = (index, text, dataIndex, record) => {
        return <Form.Item
            name={`data_grid__${index}__${dataIndex}`}
            label={""}
            key={dataIndex}
            initialValue={text}
            className='insert-infor'
        >
            <Input
                type="text"
                name="input1"
                id={dataIndex + index}
                value={text}
                // disabled={checkDisabled}
                // readOnly={dataIndex === "production" ? true : false}
                onKeyDown={(e) => handleKeyPressChange(e, dataIndex, index, `data_grid__${index}__${dataIndex}`)}
            >
            </Input>
        </Form.Item>
    }

    const handleKeyPressChange = (e, dataIndex, index, currentField) => {
        var input = document.getElementById(dataIndex + index);
        var nameColumn = dataIndex;
        var indexColumn = index;
        if (parseInt(dataPumb.is_master) === 0) {
            setIsCheckLogic(false)
        }
        if (e.ctrlKey) {
            if (e.code === "ArrowDown") {
                if (index === dataGridLastCheck.length - 1) {
                    return;
                } else {
                    input = document.getElementById(nameColumn + (indexColumn + 1));
                    e.preventDefault();
                    input.focus();
                }
            } else if (e.code === "ArrowRight") {
                if (
                    nameColumn === "Master" &&
                    index === dataGridLastCheck.length - 1
                ) {
                    return;
                } else {
                    switch (nameColumn) {
                        case "checksheet":
                            nameColumn = "Name_plate";
                            break;
                        case "Name_plate":
                            nameColumn = "Production_Instructions";
                            break;
                        case "Production_Instructions":
                            nameColumn = "Tem";
                            break;
                        case "Tem":
                            nameColumn = "Master";
                            break;
                        case "Master":
                            nameColumn = "checksheet";
                            indexColumn = indexColumn + 1;
                            break;
                        default:
                    }
                    input = document.getElementById(nameColumn + indexColumn);
                    e.preventDefault();
                    input.focus();
                }
            } else if (e.code === "ArrowLeft") {
                if (nameColumn === "checksheet" && index === 0) {
                    return;
                } else {
                    switch (nameColumn) {
                        case "checksheet":
                            nameColumn = "Master";
                            indexColumn = indexColumn - 1;
                            break;
                        case "Name_plate":
                            nameColumn = "checksheet";
                            break;
                        case "Production_Instructions":
                            nameColumn = "Name_plate";
                            break;
                        case "Tem":
                            nameColumn = "Production_Instructions";
                            break;
                        case "Master":
                            nameColumn = "Tem";
                            break;
                        default:
                    }
                    input = document.getElementById(nameColumn + indexColumn);
                    e.preventDefault();
                    input.focus();
                }
            } else if (e.code === "ArrowUp") {
                if (index === 0) {
                    return;
                } else {
                    input = document.getElementById(nameColumn + (indexColumn - 1));
                    e.preventDefault();
                    input.focus();
                }
            }
        }

        else if (e.shiftKey) {
            if (listKeyShortcuts.includes(e.key)) {
                e.preventDefault();
            }
        }

        else if (e.altKey) {
            if (e.code.slice(0, 5) === "Digit") {
                if (e.altKey && e.key === "1" && currentField) {
                    functionSetData(e, "☑", currentField);
                } else if (e.altKey && e.key === "2" && currentField) {
                    functionSetData(e, "✔", currentField);
                } else if (e.altKey && e.key === "3" && currentField) {
                    functionSetData(e, "✖", currentField);
                } else if (e.altKey && e.key === "4" && currentField) {
                    functionSetData(e, "―", currentField);
                } else if (e.altKey && e.key === "5" && currentField) {
                    functionSetData(e, "φ", currentField);
                }
            }
        }
    };

    const functionSetData = (event, value, currentField) => {
        // Lấy danh sách các field trong formGrid
        const fields = formGrid.getFieldsValue();
        // Duyệt qua từng field
        Object.keys(fields).forEach((fieldKey) => {
            if (fieldKey === currentField) {
                formGrid.setFieldsValue({
                    [currentField]: formGrid.getFieldValue()[currentField] + value,
                });
                return;
            }
        });
        event.preventDefault();
        return;
    };

    useEffect(() => {
        formGrid.resetFields()

        if (dataGridLastCheck.length > 0) {
            setLoadingTableGrid(false)
        }
    }, [dataGridLastCheck]);

    const getRowClassName = (record, index) => {
        if (listIndexLogicGrid.includes(index)) {
            return "bg-not-qualified";
        } else {
            return null;
        }
    };

    const memoizedRowClassName = useMemo(() => getRowClassName, [listIndexLogicGrid]);
    return (
        <>
            <Form
                form={formGrid}
            >
                <Table
                    style={{ marginTop: 15, width: "60%" }}
                    size="small"
                    columns={columns}
                    loading={loadingTableGrid}
                    dataSource={dataGridLastCheck}
                    pagination={false}
                    scroll={{
                        y: "25vh",
                    }}
                    rowClassName={memoizedRowClassName}
                ></Table>
            </Form>
        </>
    )
}

export default TableGridLastCheck