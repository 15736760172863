import axios from "axios";
import { endpoint, loginURL, token_refresh_URL } from "../constants";
import Cookies from "universal-cookie";
import { localhost } from "../server";
// import { ToastError } from "../components/ToastSwal";

const cookies = new Cookies();



const authAxiosTest = () => {
  // console.log(sessionStorage.getItem("info_user"))
  const userId = JSON.parse(sessionStorage.getItem("info_user")).user_id
  const token = cookies.get(`token_iwaki_${userId}`);

  const axiosTest = axios.create({
    baseURL: endpoint,
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json"
      // Content-Type: "application/json"
    },
  });
  axiosTest.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return new Promise((resolve, reject) => {
        const oroginReq = error.config;
        try {
          if (error.response.status === 400) {
            reject(error.response);
          }
          else if (error.response.status === 404 || error.response.status === 500) {
            reject(error.response);
          }
          else if (error.response.status === 403) {
            // ToastError.fire({
            //   icon: "error",
            //   title: "Bạn không có quyền xem chức năng này",
            // });
            reject(error.response);
            // message.error("Không tìm thấy dữ liệu");
          } else if (error.response.status === 401 && error.config) {
            oroginReq._retry = true;
            let refresh = cookies.get(`refresh_iwaki_${userId}`);


            if (refresh) {
              // cookies.remove("token_iwaki")

              // const axiosInstance = axios.create({
              //   baseURL: `${localhost}`,
              //   headers: {
              //     // 'Content-Type': 'application/json',
              //     'Authorization': `Bearer ${token}`
              //   }
              // });
              // axiosInstance.post("/refresh")
              //   .then(response => {
              //     console.log('Refreshed token:', response.data);
              //     // Lưu lại token mới trong local storage hoặc session storage
              //   })
              //   .catch(error => {
              //     console.error('Error refreshing token:', error);
              //   });


              let res = fetch(token_refresh_URL, {
                method: "POST",
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                  // "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                  refresh: cookies.get(`refresh_iwaki_${userId}`),
                }),
              })
                .then((res) => console.log(res))
                .then((res) => {
                  console.log(res)
                  oroginReq.headers.Authorization = `Bearer ${res.access}`;
                  // cookies.set("token_iwaki", res.access);
                  return axios(oroginReq);
                })
                .catch(err => { // Hết hạn cookies refresh                  
                  sessionStorage.clear()
                  window.location = "/login"
                  cookies.remove(`token_iwaki_${userId}`);
                  cookies.remove(`refresh_iwaki_${userId}`);
                });
              resolve(res);


              // axios
              //   .post(loginURL, {
              //     user_name: "lc-huynp",
              //     user_pw: "Rs@12345",
              //   })
              //   .then((res) => {
              //     const token = res.data.access_token;
              //     const refresh = res.data.refresh_token;
              //     cookies.set("token_iwaki", token);
              //     cookies.set("refresh_iwaki", refresh);
              //   });

              // axios
              //   .post(token_refresh_URL, {
              //     refresh: cookies.get("refresh_iwaki"),
              //     headers: {
              //       "Content-Type": "application/json",
              //     },
              //   })
              //   .then((res) => res.json())
              //   .then((res) => {
              //     console.log(res)
              //     oroginReq.headers.Authorization = `Bearer ${res.access}`;
              //     // cookies.set("token",res.access);
              //     return axios(oroginReq);
              //   })
              //   .catch((err) => {
              //     console.log(err)
              //   });
            } else {
              // ToastError.fire({
              //   icon: "error",
              //   title: "You are not signed in.",
              // });
            }
          }
        } catch (e) {
          localStorage.setItem("error_s", "1");
        }
        return error;
        // return Promise.reject(error);
      });
    }
  );
  return axiosTest;
};

export const authAxios = () => authAxiosTest();
