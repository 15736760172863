/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ManagementDashboard from "./ManagementDashboard";
import "./style.scss";
import axios from "axios";
import { localhost } from "../../server";
import dayjs from "dayjs";
import { authAxios } from "../../api/axiosClient";
import { valueDashBoard } from "./data";

const ManagementDashboardIndex = () => {
  const currentDate = dayjs();

  const [isOpenModalChecksheets, setIsOpenModalChecksheets] = useState(false);
  const [isOpenModalDailyLC, setIsOpenModalDailyLC] = useState(false);
  const [isOpenModalDailyAverage, setIsOpenModalDailyAverage] = useState(false);
  const [isOpenTable, setIsOpenTable] = useState(false);
  const [listPackageAll, setListPackageAll] = useState([]);
  const [isValueDashBoard, setIsValueDashBoard] = useState([]);
  const [isValueCheckSheets, setIsValueCheckSheets] = useState([]);
  const [isValueDailyNotqualified, setIsValueDailyNotqualified] = useState([]);
  const [isValueDailyNG, setIsValueDailyNG] = useState([]);
  const [isValueDailyAll, setIsValueDailyAll] = useState([]);
  const [isValueDailyAverage, setIsValueDailyAverage] = useState([]);
  const [checkValueResult, setCheckValueResult] = useState(undefined);
  const [checkValueStatus, setCheckValueStatus] = useState(undefined);
  const [checkClickSearch, setCheckClickSearch] = useState(false);
  const [
    isValueDatePickerModalCheckSheet,
    setIsValueDatePickerModalCheckSheet,
  ] = useState(undefined);
  const [isValueDatePickerModalDaily, setIsValueDatePickerModalDaily] =
    useState(undefined);
  const [isValueAverageTimeLc, setIsValueAverageTimeLc] = useState(undefined);

  const [
    isValueDatePickerModalDailyAverage,
    setIsValueDatePickerModalDailyAverage,
  ] = useState(undefined);

  const [isValueDatePickerManagement, setIsValueDatePickerManagement] =
    useState(undefined);
  const [valueSearch, setValueSearch] = useState([]);
  const [checkValueSearch, setCheckValueSearch] = useState(undefined);
  const inforUser = JSON.parse(sessionStorage.getItem("info_user"));

  const handleClickOpenModalChecksheets = () => {
    setIsOpenModalChecksheets((prevState) => !prevState);
  };

  const handleClickOpenModalDailyLC = () => {
    setIsOpenModalDailyLC((prevState) => !prevState);
  };

  const handleClickOpenModalDailyAverage = () => {
    setIsOpenModalDailyAverage((prevState) => !prevState);
  };

  const fetchListPackageAll = () => {
    authAxios()
      .post(`${localhost}/dash_board_content`, {
        user_role: inforUser.user_role,
        date_time:
          isValueDatePickerManagement === undefined
            ? currentDate.format("YYMMDD")
            : isValueDatePickerManagement,
      })
      .then((res) => {
        setListPackageAll(res.data.lst_view);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickButtonOpenTable = () => {
    setIsOpenTable((prevState) => !prevState);
    setCheckValueSearch(undefined);
    setCheckClickSearch(false);
    setValueSearch([]);
  };

  const fetchListDashBoard = () => {

    authAxios()
      .get(`${localhost}/dash_board`,
        {
          params: {
            user_role: inforUser.user_role
          },
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((res) => {
        setIsValueDashBoard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (isValueDashBoard.length !== 0) {
      if (isValueDashBoard.average_time_lc.length === 0) {
        setIsValueAverageTimeLc(0);
      } else {
        const total = isValueDashBoard.average_time_lc.reduce(
          (acc, num) => acc + num,
          0
        );
        const average = total / isValueDashBoard.average_time_lc.length;
        setIsValueAverageTimeLc(Math.round(average));
      }
    }
  }, [isValueDashBoard]);

  const fetchListMonthlyChecksheet = () => {
    authAxios()
      .post(`${localhost}/monthly_checksheet`, {
        user_role: inforUser.user_role,
        date_time:
          isValueDatePickerModalCheckSheet === undefined
            ? currentDate.format("YYMM")
            : isValueDatePickerModalCheckSheet,
      })
      .then((res) => {
        setIsValueCheckSheets(res.data.lst_view);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchListMonthlyQaNotq = () => {
    authAxios()
      .post(`${localhost}/monthly_qa_notq`, {
        user_role: inforUser.user_role,

        date_time:
          isValueDatePickerModalDaily === undefined
            ? currentDate.format("YYMM")
            : isValueDatePickerModalDaily,
      })
      .then((res) => {
        setIsValueDailyNotqualified(res.data.lst_notqualified);
        setIsValueDailyNG(res.data.lst_ng);
        setIsValueDailyAll(res.data.lst_all);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchListMonthlyAveTimeLC = () => {
    authAxios()
      .post(`${localhost}/monthly_ave_timelc`, {
        user_role: inforUser.user_role,

        date_time:
          isValueDatePickerModalDailyAverage === undefined
            ? currentDate.format("YYMM")
            : isValueDatePickerModalDailyAverage,
      })
      .then((res) => {
        setIsValueDailyAverage(res.data.lst_all);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchListDashBoard();
  }, []);

  useEffect(() => {
    if (
      isOpenModalChecksheets === true ||
      isValueDatePickerModalCheckSheet !== undefined
    ) {
      fetchListMonthlyChecksheet();
    }
  }, [isOpenModalChecksheets, isValueDatePickerModalCheckSheet]);

  useEffect(() => {
    if (
      isOpenModalDailyLC === true ||
      isValueDatePickerModalDaily !== undefined
    ) {
      fetchListMonthlyQaNotq();
    }
  }, [isOpenModalDailyLC, isValueDatePickerModalDaily]);

  useEffect(() => {
    if (
      isOpenModalDailyAverage === true ||
      isValueDatePickerModalDailyAverage !== undefined
    ) {
      fetchListMonthlyAveTimeLC();
    }
  }, [isOpenModalDailyAverage, isValueDatePickerModalDailyAverage]);

  useEffect(() => {
    if (isOpenTable === true || isValueDatePickerManagement !== undefined) {
      fetchListPackageAll();
    }
  }, [isOpenTable, isValueDatePickerManagement]);

  const handleDatePickerModalCheckSheet = (date) => {
    if (date) {
      const yymm = date.format("YYMM");
      setIsValueDatePickerModalCheckSheet(yymm);
    }
  };

  const handleDatePickerModalDaily = (date) => {
    if (date) {
      const yymm = date.format("YYMM");
      setIsValueDatePickerModalDaily(yymm);
    }
  };

  const handleDatePickerModalDailyAverage = (date) => {
    if (date) {
      const yymm = date.format("YYMM");
      setIsValueDatePickerModalDailyAverage(yymm);
    }
  };

  const handleDatePickerManagement = (date) => {
    if (date) {
      const yymm = date.format("YYMMDD");
      setIsValueDatePickerManagement(yymm);
      setCheckValueSearch(undefined);
      setCheckValueStatus(undefined);
      setCheckValueResult(undefined);
      setCheckClickSearch(false);
    }
  };

  const handleSearchTableManagemant = (e) => {
    setCheckValueSearch(e.target.value);
  };

  const handleChangeResult = (e) => {
    setCheckValueResult(e);
  };
  const handleChangeStatus = (e) => {
    setCheckValueStatus(e);
  };

  const handleClickSearch = () => {
    setCheckClickSearch(true);

    if (
      checkValueStatus !== undefined &&
      checkValueResult === undefined &&
      checkValueSearch === undefined
    ) {
      const valueSS = listPackageAll
        .filter((value) => value.status === checkValueStatus)
        .map((item) => {
          return item;
        });
      setValueSearch(valueSS);
    } else if (
      checkValueStatus === undefined &&
      checkValueResult !== undefined &&
      checkValueSearch === undefined
    ) {
      const valueSS = listPackageAll
        .filter((value) => value.result === checkValueResult)
        .map((item) => {
          return item;
        });
      setValueSearch(valueSS);
    } else if (
      checkValueStatus === undefined &&
      checkValueResult === undefined &&
      checkValueSearch !== undefined
    ) {
      const keyword = checkValueSearch.toUpperCase();
      const valueSS = listPackageAll
        .filter(
          (value) =>
            value.vl_mfg_no.toUpperCase().includes(keyword) ||
            value.vl_model_name.toUpperCase().includes(keyword)
        )
        .map((item) => {
          return item;
        });
      setValueSearch(valueSS);
    } else if (
      checkValueStatus !== undefined &&
      checkValueResult !== undefined &&
      checkValueSearch === undefined
    ) {
      const valueSSStatus = listPackageAll
        .filter((value) => value.status === checkValueStatus)
        .map((item) => {
          return item;
        });

      const valueSSResult = valueSSStatus
        .filter((value) => value.result === checkValueResult)
        .map((item) => {
          return item;
        });

      setValueSearch(valueSSResult);
    } else if (
      checkValueStatus !== undefined &&
      checkValueResult === undefined &&
      checkValueSearch !== undefined
    ) {
      const valueSSStatus = listPackageAll
        .filter((value) => value.status === checkValueStatus)
        .map((item) => {
          return item;
        });

      const keyword = checkValueSearch.toUpperCase();
      const valueSS = valueSSStatus
        .filter(
          (value) =>
            value.vl_mfg_no.toUpperCase().includes(keyword) ||
            value.vl_model_name.toUpperCase().includes(keyword)
        )
        .map((item) => {
          return item;
        });

      setValueSearch(valueSS);
    } else if (
      checkValueStatus === undefined &&
      checkValueResult !== undefined &&
      checkValueSearch !== undefined
    ) {
      const valueSSStatus = listPackageAll
        .filter((value) => value.result === checkValueResult)
        .map((item) => {
          return item;
        });

      const keyword = checkValueSearch.toUpperCase();
      const valueSS = valueSSStatus
        .filter(
          (value) =>
            value.vl_mfg_no.toUpperCase().includes(keyword) ||
            value.vl_model_name.toUpperCase().includes(keyword)
        )
        .map((item) => {
          return item;
        });

      setValueSearch(valueSS);
    } else if (
      checkValueStatus !== undefined &&
      checkValueResult !== undefined &&
      checkValueSearch !== undefined
    ) {
      const valueSSStatus = listPackageAll
        .filter((value) => value.status === checkValueStatus)
        .map((item) => {
          return item;
        });

      const valueSSResult = valueSSStatus
        .filter((value) => value.result === checkValueResult)
        .map((item) => {
          return item;
        });

      const keyword = checkValueSearch.toUpperCase();
      const valueSS = valueSSResult
        .filter(
          (value) =>
            value.vl_mfg_no.toUpperCase().includes(keyword) ||
            value.vl_model_name.toUpperCase().includes(keyword)
        )
        .map((item) => {
          return item;
        });

      setValueSearch(valueSS);
    } else if (
      checkValueStatus === undefined &&
      checkValueResult === undefined &&
      checkValueSearch === undefined
    ) {
      setValueSearch(listPackageAll);
    }
  };

  return (
    <ManagementDashboard
      isOpenModalChecksheets={isOpenModalChecksheets}
      handleClickOpenModalChecksheets={handleClickOpenModalChecksheets}
      isOpenModalDailyLC={isOpenModalDailyLC}
      handleClickOpenModalDailyLC={handleClickOpenModalDailyLC}
      isOpenModalDailyAverage={isOpenModalDailyAverage}
      handleClickOpenModalDailyAverage={handleClickOpenModalDailyAverage}
      isOpenTable={isOpenTable}
      listPackageAll={listPackageAll}
      isValueDashBoard={isValueDashBoard}
      isValueCheckSheets={isValueCheckSheets}
      isValueDailyNotqualified={isValueDailyNotqualified}
      isValueDailyNG={isValueDailyNG}
      isValueDailyAll={isValueDailyAll}
      isValueDailyAverage={isValueDailyAverage}
      handleClickButtonOpenTable={handleClickButtonOpenTable}
      handleDatePickerModalCheckSheet={handleDatePickerModalCheckSheet}
      handleDatePickerModalDaily={handleDatePickerModalDaily}
      handleDatePickerModalDailyAverage={handleDatePickerModalDailyAverage}
      handleDatePickerManagement={handleDatePickerManagement}
      handleSearchTableManagemant={handleSearchTableManagemant}
      valueSearch={valueSearch}
      checkValueSearch={checkValueSearch}
      handleChangeResult={handleChangeResult}
      handleClickSearch={handleClickSearch}
      handleChangeStatus={handleChangeStatus}
      checkClickSearch={checkClickSearch}
      checkValueStatus={checkValueStatus}
      checkValueResult={checkValueResult}
      isValueAverageTimeLc={isValueAverageTimeLc}
    />
  );
};

export default ManagementDashboardIndex;
