import { Button, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import ModalShowCheckLogic from '../modal/ModalShowCheckLogic'
import ModalDataMaster from '../modal/ModalDataMaster'
import axios from 'axios'
import { localhost } from '../../../server'
import { SaveOutlined } from '@ant-design/icons'
import { authAxios } from '../../../api/axiosClient'

const RowButton = (
    {
        dataLastCheck,
        pumpId,
        setListNoCheckLogic,
        dataDetail,
        setDataLastCheck,
        listDataDefault,
        dataPumb,
        form,
        setListNotQualified,
        setIsCheckLogic,
        setIsSortData,
        isSortData,
        newDataTable,
        listNotQualified,
        listNoException,
        setLoadingMainTable,
        setListIndexLogicGrid,
        formGrid,
        dataGridLastCheck,
        listRuleCompare,
        setListLogicMulti,
        listLogicMulti,
        isCheckShowDataMaster,
        setIsCheckShowDataMaster
    }) => {
    const [isOpenModalCheckLogic, setIsOpenModalCheckLogic] = useState(false)
    const [isOpenModalDataMaster, setIsOpenModalDataMaster] = useState(false)
    // const [isCheckShowDataMaster, setIsCheckShowDataMaster] = useState(false)
    const [listDataMaster, setListDataMaster] = useState([])
    const [loadingTable, setLoadingTable] = useState(false)
    const [isFirstCallOnFinish, setIsFirstCallOnFinish] = useState(false)
    const [preNewArr, setPreNewArr] = useState([])
    const inforUser = JSON.parse(sessionStorage.getItem("info_user"));


    const checkLogicData = () => {

        // dataPumb.is_master === 0 là MDG
        // dataPumb.is_master === 1 là LK
        if (parseInt(dataPumb.is_master) === 0) {
            checkLogicGrid()
        }

        setIsOpenModalCheckLogic(true)
    }

    const showModalSetDataMaster = () => {
        setIsOpenModalDataMaster(true)
    }

    const isPumpHaveMaster = parseInt(dataPumb.is_master) === 1

    const functionSetArrData = (data, arrData) => {
        data.forEach(item => {
            arrData.push({
                No: item.No,
                field_name: item.field_name,
                checksheet: item.checksheet,
                Tem: item.Tem,
                Result: item.Result,
                Production_Instructions: item.Production_Instructions,
                Name_plate: item.Name_plate,
                Master: item.Master,
                rule_point: item.rule_point,
                check_result: item.check_result,
                mark: item.mark,
                vl_rule: item.vl_rule,
                mark_checksheet: item.mark_checksheet,
                mark_Production_Instructions: item.mark_Production_Instructions,
                mark_Name_plate: item.mark_Name_plate,
                mark_Tem: item.mark_Tem,
                other_rule: item.other_rule,
                is_qualified: item.is_qualified,
                no_compair: item.no_compair,
            })
        })
    }

    const funcDataCheckLogic = (data, dataForm, isSetDataForm) => {
        if (isSetDataForm) {
            Object.keys(dataForm).map(item => {
                var arr_key = item.split("__");
                form.setFieldValue(item, data[arr_key[1]][arr_key[2]])
            });
        } else {
            Object.keys(dataForm).map(item => {
                var arr_key = item.split("__");
                data[arr_key[1]][arr_key[2]] = dataForm[item]
            });
        }
    }

    const someAsyncFunction = async () => {
        // Thực hiện một số tác vụ bất đồng bộ
        setLoadingMainTable(true);
        setLoadingTable(true)
        return new Promise((resolve) => setTimeout(resolve, 200));
    };

    const onFinish = async (values) => {
        await someAsyncFunction()
        const dataForm = form.getFieldValue()
        let arrData = []

        funcDataCheckLogic(listDataDefault, dataForm, false)

        functionSetArrData(listDataDefault, arrData)
        // if (isFirstCallOnFinish === false) {
        //     setIsFirstCallOnFinish(true)
        // }
        let newArr = []
        for (let i = 1; i < 9; i++) {
            if (values[`input_${i}`] === undefined) {
                newArr.push("")
            } else {
                newArr.push(values[`input_${i}`])
            }
        }
        const dataNo40 = arrData.filter(item => parseInt(item.No) === 40)
        const FormData = require("form-data");
        let data = new FormData();
        data.append("lst_master", newArr);
        data.append("pump_id", dataPumb.value);
        data.append("no40_vl", dataNo40[0].checksheet);
        data.append("user_role", inforUser.user_role);
        authAxios()
            .post(`${localhost}/view_master`, data).then(res => {
                let listIndexHaveMaster = []
                for (let i = 0; i < arrData.length; i++) {
                    for (let j = 0; j < res.data.lst_master.length; j++) {
                        if (arrData[i].No === res.data.lst_master[j].no) {
                            arrData[i] = { ...arrData[i], Master: res.data.lst_master[j].m11 }
                            listIndexHaveMaster.push(i)
                            break;
                        }
                    }
                }

                listIndexHaveMaster.forEach(item => {
                    form.setFieldValue(`data_add__${item}__Master`, arrData[item].Master)
                })
                setListDataMaster(res.data.lst_master)
                saveData()

                setIsCheckShowDataMaster(true)
                setIsCheckLogic(false)
                setLoadingMainTable(false)
                setIsSortData(false)
                setLoadingTable(false)
            }).catch(err => {
                setLoadingMainTable(false)
                setLoadingTable(false)
                console.log(err)
            })
    }

    const addDataEqual = (listArrHaveContent, dataCheckSheet) => {

        // Đúng
        listArrHaveContent.push(dataCheckSheet)
        listArrHaveContent.push(dataCheckSheet)
    }

    const functionAddDataOftilede = (listArrHaveContent, item) => {
        if (item.checksheet !== "") {
            listArrHaveContent.push(item.checksheet.split("~")[0])
        }
        if (item.Name_plate !== "") {
            listArrHaveContent.push(item.Name_plate.split("~")[0])
        }
        if (item.Production_Instructions !== "") {
            listArrHaveContent.push(item.Production_Instructions.split("~")[0])
        }
        if (item.Tem !== "") {
            listArrHaveContent.push(item.Tem.split("~")[0])
        }

        // if (parseInt(item.is_qualified) === 1) {
        if (item.Master !== "") {
            listArrHaveContent.push(item.Master.split("~")[0])
        }
        // }

    }

    // function check ~ 
    const functionChecktilde = (item, listArrHaveContent) => {
        if (item.Production_Instructions.includes("~")) {
            let arrProduction = item.Production_Instructions.split("~")

            let dataCheckSheet = ''

            if (item.checksheet.includes("~")) {
                dataCheckSheet = item.checksheet.split("~")[0]
            } else {
                dataCheckSheet = item.checksheet
            }

            if (item.Name_plate !== "" && item.Tem !== "") {
                if (dataCheckSheet === item.Name_plate && dataCheckSheet === item.Tem) {
                    if (parseInt(arrProduction[0].match(/\d+/g).join('')) <= parseInt(dataCheckSheet.match(/\d+/g).join('')) && parseInt(dataCheckSheet.match(/\d+/g).join('')) <= parseInt(arrProduction[1].match(/\d+/g).join(''))) {
                        addDataEqual(listArrHaveContent, dataCheckSheet)
                    } else {
                        functionAddDataOftilede(listArrHaveContent, item)
                    }
                } else {
                    functionAddDataOftilede(listArrHaveContent, item)
                }
            } else if (item.Name_plate !== "" && item.Tem === "") {
                if (dataCheckSheet === item.Name_plate) {
                    if (parseInt(arrProduction[0].match(/\d+/g).join('')) <= parseInt(dataCheckSheet.match(/\d+/g).join('')) && parseInt(dataCheckSheet.match(/\d+/g).join('')) <= parseInt(arrProduction[1].match(/\d+/g).join(''))) {
                        addDataEqual(listArrHaveContent, dataCheckSheet)
                    } else {
                        functionAddDataOftilede(listArrHaveContent, item)
                    }
                } else {
                    functionAddDataOftilede(listArrHaveContent, item)
                }
            } else if (item.Name_plate === "" && item.Tem !== "") {
                if (dataCheckSheet === item.Tem) {
                    if (parseInt(arrProduction[0].match(/\d+/g).join('')) <= parseInt(dataCheckSheet.match(/\d+/g).join('')) && parseInt(dataCheckSheet.match(/\d+/g).join('')) <= parseInt(arrProduction[1].match(/\d+/g).join(''))) {
                        addDataEqual(listArrHaveContent, dataCheckSheet)
                    } else {
                        functionAddDataOftilede(listArrHaveContent, item)
                    }
                } else {
                    functionAddDataOftilede(listArrHaveContent, item)
                }
            } else {
                if (parseInt(arrProduction[0].match(/\d+/g).join('')) <= parseInt(dataCheckSheet.match(/\d+/g).join('')) && parseInt(dataCheckSheet.match(/\d+/g).join('')) <= parseInt(arrProduction[1].match(/\d+/g).join(''))) {
                    addDataEqual(listArrHaveContent, dataCheckSheet)
                } else {
                    functionAddDataOftilede(listArrHaveContent, item)
                }
            }
        }
    }

    // function check _
    const functionCheckUnderline = (item, listArrHaveContent) => {
        if (item.Name_plate !== "") {
            listArrHaveContent.push(item.Name_plate.split("_")[0])
        }
        if (item.Production_Instructions !== "") {
            listArrHaveContent.push(item.Production_Instructions.split("_")[0])
        }
        if (item.Tem !== "") {
            listArrHaveContent.push(item.Tem.split("_")[0])
        }

        // if (parseInt(item.is_qualified) === 1) {
        if (item.Master !== "") {
            listArrHaveContent.push(item.Master.split("_")[0])
        }
        // }
    }

    const functionAddAllDataCol = (item, listArrHaveContent) => {
        if (item.checksheet !== "") {
            listArrHaveContent.push(item.checksheet)
        }
        if (item.Name_plate !== "") {
            listArrHaveContent.push(item.Name_plate)
        }
        if (item.Production_Instructions !== "") {
            listArrHaveContent.push(item.Production_Instructions)
        }
        if (item.Tem !== "") {
            listArrHaveContent.push(item.Tem)
        }

        // if (parseInt(item.is_qualified) === 1) {
        if (item.Master !== "") {
            listArrHaveContent.push(item.Master)
        }
        // }
    }

    const functionPushData = (newArrData, item, dataResult) => {
        // item = arrData[i]
        newArrData.push({
            ...item, Result: dataResult
        })
    }

    const saveData = () => {
        console.log("Vào saveData")
        const values = form.getFieldsValue()
        let arrData = []
        let newForm = '';

        if (!isSortData) {
            funcDataCheckLogic(listDataDefault, values, false)
        } else {

            funcDataCheckLogic(newDataTable, values, false)
            dataLastCheck.forEach((item, index) => {
                if (listNotQualified[index] !== undefined) {
                    if (item.No === listNotQualified[index].No) {
                        dataLastCheck[index] = listNotQualified[index]
                    }
                }
            })
            funcDataCheckLogic(dataLastCheck, values, true)
            newForm = form.getFieldsValue()
            funcDataCheckLogic(listDataDefault, newForm, false)
        }

        if (isFirstCallOnFinish === false) {
            functionSetArrData(listDataDefault, arrData)
            let newArrData = []
            for (let i = 0; i < arrData.length; i++) {
                // if (parseInt(arrData[i].is_qualified) === 1) {
                let listArrHaveContent = [];
                // if ((arrData[i].checksheet === "/") && arrData[i].Master !== "") {
                //     // newArrData.push({
                //     //     ...arrData[i], Result: arrData[i].check_result
                //     // })

                //     functionPushData(newArrData, arrData[i], arrData[i].check_result)
                // }

                // else if (!arrData[i].Master.includes("/")) {
                if (arrData[i].checksheet.includes("_")) {
                    const dataNo3 = arrData[i].checksheet.split("_")
                    listArrHaveContent.push(dataNo3[0])

                    functionCheckUnderline(arrData[i], listArrHaveContent)
                } else if (arrData[i].Production_Instructions.includes("~")) {
                    functionChecktilde(arrData[i], listArrHaveContent)
                }
                else {
                    functionAddAllDataCol(arrData[i], listArrHaveContent)
                }
                if (parseInt(arrData[i].No) !== 31) {
                    if (parseInt(arrData[i].is_qualified) === 0) {
                        functionPushData(newArrData, arrData[i], "✔")
                    }

                    else if ((arrData[i].checksheet === "/") && arrData[i].Master !== "") {
                        // newArrData.push({
                        //     ...arrData[i], Result: arrData[i].check_result
                        // })

                        functionPushData(newArrData, arrData[i], arrData[i].check_result)
                    }
                    else if (listArrHaveContent.length === 0 || listArrHaveContent.length === 1) {
                        if (arrData[i].Master === "") {
                            // newArrData.push({
                            //     ...arrData[i], Result: arrData[i].check_result
                            // })
                            functionPushData(newArrData, arrData[i], arrData[i].check_result)

                        } else {
                            // newArrData.push({
                            //     ...arrData[i], Result: "✖"
                            // })
                            functionPushData(newArrData, arrData[i], "✖")
                        }
                    }
                    else {
                        const compareData = listArrHaveContent.every(value => value === listArrHaveContent[0])
                        if (compareData === true) {
                            // newArrData.push({
                            //     ...arrData[i], Result: arrData[i].check_result
                            // })
                            functionPushData(newArrData, arrData[i], arrData[i].check_result)

                        } else {
                            // newArrData.push({
                            //     ...arrData[i], Result: "✖"
                            // })
                            functionPushData(newArrData, arrData[i], "✖")
                        }
                    }
                } else {
                    // newArrData.push({
                    //     ...arrData[i], Result: arrData[i].check_result
                    // })
                    functionPushData(newArrData, arrData[i], arrData[i].check_result)

                }
                // }
                // else {
                //     if (arrData[i].checksheet.includes("/")) {
                //         if (arrData[i].checksheet === arrData[i].Master) {
                //             // newArrData.push({
                //             //     ...arrData[i], Result: "✔"
                //             // })
                //             functionPushData(newArrData, arrData[i], "✔")
                //         } else {
                //             // newArrData.push({
                //             //     ...arrData[i], Result: "✖"
                //             // })
                //             functionPushData(newArrData, arrData[i], "✖")
                //         }
                //     } else {
                //         const arrMaster = arrData[i].Master.split("/")
                //         if (arrMaster.includes(arrData[i].checksheet)) {
                //             // newArrData.push({
                //             //     ...arrData[i], Result: "✔"
                //             // })
                //             functionPushData(newArrData, arrData[i], "✔")
                //         } else {
                //             // newArrData.push({
                //             //     ...arrData[i], Result: "✖"
                //             // })
                //             functionPushData(newArrData, arrData[i], "✖")
                //         }
                //     }
                // }
                // } else {
                //     functionPushData(newArrData, arrData[i], "✔")
                // }
            }

            let listResultException = []

            newArrData.forEach((item, index) => {
                if (listNoException.includes(item.No)) {
                    listResultException.push(item)
                }
            })
            setPreNewArr(listResultException)

            const newDataForm = form.getFieldsValue()
            funcDataCheckLogic(newArrData, newDataForm, true)

            let dataNotQualified = newArrData.filter(item => item.Result === "✖")

            setListNotQualified(dataNotQualified)
            setDataLastCheck(newArrData)
            console.log(newArrData)


        } else {
            let listNewResult = []
            functionSetArrData(listDataDefault, arrData)
            let listData = []
            for (let i = 0; i < arrData.length; i++) {
                if (listNoException.includes(arrData[i].No)) {
                    listData.push({
                        No: arrData[i].No,
                        Result: arrData[i].Result,
                        index: i,
                    })
                }
            }

            // So sánh các hàng k ngoại lệ
            for (let i = 0; i < arrData.length; i++) {
                let listArrHaveContent = [];
                // if (parseInt(arrData[i].is_qualified) === 1) {
                if ((arrData[i].checksheet === "/") && arrData[i].Master !== "") {
                    arrData[i].Result = arrData[i].check_result
                }
                else if (!arrData[i].Master.includes("/")) {
                    if (arrData[i].checksheet.includes("_")) {
                        const dataNo3 = arrData[i].checksheet.split("_")
                        listArrHaveContent.push(dataNo3[0])

                        functionCheckUnderline(arrData[i], listArrHaveContent)
                    } else if (arrData[i].Production_Instructions.includes("~")) {

                        functionChecktilde(arrData[i], listArrHaveContent)
                    }
                    else {

                        functionAddAllDataCol(arrData[i], listArrHaveContent)
                    }
                    if (parseInt(arrData[i].No) !== 31) {
                        if (listArrHaveContent.length === 0 || listArrHaveContent.length === 1) {
                            if (arrData[i].Master === "") {
                                arrData[i].Result = arrData[i].check_result
                            } else {
                                arrData[i].Result = "✖"
                            }
                        }
                        else {
                            const compareData = listArrHaveContent.every(value => value === listArrHaveContent[0])
                            if (compareData === true) {
                                arrData[i].Result = arrData[i].check_result
                            } else {
                                arrData[i].Result = "✖"

                            }
                        }
                    } else {
                        arrData[i].Result = arrData[i].check_result
                    }
                } else {
                    if (arrData[i].checksheet.includes("/")) {
                        if (arrData[i].checksheet === arrData[i].Master) {
                            arrData[i].Result = arrData[i].check_result
                        } else {
                            arrData[i].Result = "✖"
                        }
                    } else {
                        const arrMaster = arrData[i].Master.split("/")
                        if (arrMaster.includes(arrData[i].checksheet)) {
                            arrData[i].Result = arrData[i].check_result
                        } else {
                            arrData[i].Result = "✖"
                        }
                    }
                }
                // } else {
                //     arrData[i].Result = "✔"
                // }
            }

            // So sánh các hàng ngoại lệ và cho phép sửa Result
            for (let i = 0; i < listData.length; i++) {
                if (preNewArr[i].Result !== listData[i].Result) {
                    arrData[listData[i].index].Result = listData[i].Result
                } else {
                    let listArrHaveContent = [];
                    let indexFalse = arrData[listData[i].index]
                    if ((indexFalse.checksheet === "/") && indexFalse.Master !== "") {
                        indexFalse.Result = indexFalse.check_result
                    }
                    else if (!indexFalse.Master.includes("/")) {
                        if (indexFalse.checksheet.includes("~")) {
                            const dataNo3 = indexFalse.checksheet.split("_")
                            listArrHaveContent.push(dataNo3[0])

                            functionCheckUnderline(indexFalse, listArrHaveContent)
                        } else if (indexFalse.checksheet.includes("_")) {
                            functionChecktilde(indexFalse, listArrHaveContent)
                        }

                        else {
                            functionAddAllDataCol(indexFalse, listArrHaveContent)
                        }

                        if (listArrHaveContent.length === 0 || listArrHaveContent.length === 1) {
                            if (indexFalse.Master === "") {
                                indexFalse.Result = indexFalse.check_result
                            } else {
                                indexFalse.Result = "✖"
                            }
                        }
                        else {
                            const compareData = listArrHaveContent.every(value => value === listArrHaveContent[0])
                            if (compareData === true) {
                                indexFalse.Result = indexFalse.check_result
                            } else {
                                indexFalse.Result = "✖"
                            }
                        }
                    }
                    else {
                        if (indexFalse.checksheet.includes("/")) {
                            if (indexFalse.checksheet === indexFalse.Master) {
                                indexFalse.Result = indexFalse.check_result
                            } else {
                                indexFalse.Result = "✖"
                            }
                        } else {
                            const arrMaster = indexFalse.Master.split("/")
                            if (arrMaster.includes(indexFalse.checksheet)) {
                                indexFalse.Result = indexFalse.check_result
                            } else {
                                indexFalse.Result = "✖"
                            }
                        }
                    }
                }
            }

            setPreNewArr(listNewResult)

            funcDataCheckLogic(arrData, form.getFieldsValue(), true)
            let dataNotQualified = arrData.filter(item => item.Result === "✖")

            arrData.forEach((item, index) => {
                if (listNoException.includes(item.No)) {
                    listNewResult.push(item)
                }
            })

            setListNotQualified(dataNotQualified)
            setDataLastCheck(arrData)
        }
    }

    const checkLogicGrid = () => {
        const dataFormGrid = formGrid.getFieldsValue();
        let listIndexLogic = []
        let newData = dataGridLastCheck

        // true set form
        // false set data
        funcDataCheckLogic(newData, dataFormGrid, false)
        newData.forEach((item, index) => {
            let arrProduction = item.production.split("~")
            let newArr = []
            try {
                for (let i = 0; i < listRuleCompare.length; i++) {
                    if (arrProduction[0][0] === listRuleCompare[i].value) {
                        newArr.push(arrProduction[0].replace(arrProduction[0][0], listRuleCompare[i].month))
                    }
                    if (arrProduction[1][0] === listRuleCompare[i].value) {
                        console.log(arrProduction[1][0])
                        newArr.push(arrProduction[1].replace(arrProduction[1][0], listRuleCompare[i].month))
                    }
                }
                if (item.mfg_no === "") {
                    listIndexLogic.push(index)
                } else {
                    let newNumber = "0" + item.mfg_no.slice(-5)
                    let newDataCompare = item.mfg_no.replace(item.mfg_no.slice(-5), newNumber)
                    if (!(parseInt(newArr[0]) <= parseInt(newDataCompare) && parseInt(newDataCompare) <= parseInt(newArr[1]))) {
                        listIndexLogic.push(index)
                    }
                }
            } catch (err) {
                console.log(err)
            }
        })
        setListIndexLogicGrid(listIndexLogic)
        // saveDataMDG()
    }

    const saveDataMDG = () => {
        const dataFormInput = form.getFieldsValue()
        let newData = dataLastCheck
        console.log(newData)
        funcDataCheckLogic(newData, dataFormInput, false)

        let dataNotQualified = newData.filter(item => item.Result === "✖")
        setListNotQualified(dataNotQualified)

        setIsCheckLogic(true)
        checkLogicGrid()
    }

    useEffect(() => {
        if (listLogicMulti.length > 0) {
            let arrIndex = []
            let newArrData = dataLastCheck
            const dataForm = form.getFieldsValue()
            for (let i = 0; i < dataLastCheck.length; i++) {
                for (let j = 0; j < listLogicMulti.length; j++) {
                    if (dataLastCheck[i].No === listLogicMulti[j].no) {
                        arrIndex.push({
                            index: i,
                            result: listLogicMulti[j].result
                        })
                        break;
                    }
                }
            }

            arrIndex.forEach(item => {
                newArrData[item.index].Result = item.result
            })
            Object.keys(dataForm).map(item => {
                var arr_key = item.split("__");
                form.setFieldValue(item, newArrData[arr_key[1]][arr_key[2]])
            });

            let dataNotQualified = newArrData.filter(item => item.Result === "✖")
            setListNotQualified(dataNotQualified)
            setDataLastCheck(newArrData)
        }

    }, [listLogicMulti]);

    return (
        <>
            <Col span={8} style={{ display: "flex", justifyContent: "flex-end", columnGap: "2ch" }}>
                {isPumpHaveMaster &&
                    <Button disabled={!isCheckShowDataMaster} onClick={onFinish}><SaveOutlined /></Button>
                }
                {!isPumpHaveMaster &&
                    <Button onClick={saveDataMDG}><SaveOutlined /></Button>
                }

                {parseInt(dataDetail.is_checksheet) === 1 &&
                    <Button disabled={isPumpHaveMaster ? !isCheckShowDataMaster : false} onClick={checkLogicData}>Check logic</Button>
                }

                {isPumpHaveMaster &&
                    <Button onClick={showModalSetDataMaster}>View data master</Button>
                }
            </Col>
            {isOpenModalCheckLogic &&
                <ModalShowCheckLogic
                    isOpenModalCheckLogic={isOpenModalCheckLogic}
                    setIsOpenModalCheckLogic={setIsOpenModalCheckLogic}
                    setIsCheckLogic={setIsCheckLogic}
                    dataLastCheck={dataLastCheck}
                    pumpId={pumpId}
                    setListNoCheckLogic={setListNoCheckLogic}
                    form={form}
                    isSortData={isSortData}
                    newDataTable={newDataTable}
                    setListLogicMulti={setListLogicMulti}
                    setListNotQualified={setListNotQualified}
                    setDataLastCheck={setDataLastCheck}
                    dataDetail={dataDetail}
                />
            }

            <ModalDataMaster

                // dataDetail={dataDetail}
                // setDataLastCheck={setDataLastCheck}
                // listDataDefault={listDataDefault}
                // dataPumb={dataPumb}
                // form={form}
                // setIsCheckShowDataMaster={setIsCheckShowDataMaster}
                // setIsCheckLogic={setIsCheckLogic}
                // setListNotQualified={setListNotQualified}
                // setIsSortData={setIsSortData}
                // isSortData={isSortData}


                isOpenModalDataMaster={isOpenModalDataMaster}
                setIsOpenModalDataMaster={setIsOpenModalDataMaster}
                dataLastCheck={dataLastCheck}
                onFinish={onFinish}
                loadingTable={loadingTable}
                listDataMaster={listDataMaster}
            />
        </>
    )
}

export default RowButton