/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, Table } from "antd";
import DetailIcon from "./../../images/ViewDetail.svg";
import SearchIcon from "./../../images/search.svg";
import ResetFields from "./../../images/ResetFieldFilter.svg";

import "./Management.css";
import dayjs from "dayjs";
import ModalDetail from "./ModalDetail";
import axios from "axios";
import { localhost } from "../../server";
import { authAxios } from "../../api/axiosClient";
const { Option } = Select;

function Management() {
  const [dataSource, setDataSource] = useState([]);
  const [form] = Form.useForm();
  const inforUser = JSON.parse(sessionStorage.getItem("info_user"));

  const status = [
    {
      id: -1,
      value: "Đang xử lí",
    },
    {
      id: 0,
      value: "Qualified",
    },
    {
      id: 1,
      value: "Not Qualified",
    },
  ];

  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const [dataRecord, setDataRecord] = useState();
  const [pager, setPager] = useState({
    pageSize: 10,
    count: 0,
    current: 1,
  });

  const [fieldFilter, setFieldFilter] = useState({
    mfg_no: null,
    model_name: null,
    send_date: null,
    status: null,
  });

  const openModalDetail = (value) => {
    setDataRecord(value);
    setIsOpenModalDetail(true);
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      ellipsis: true,
      width: 60,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },

    {
      title: "MFG No.",
      dataIndex: "value_mfg_no",
      key: "value_mfg_no",
      align: "center",
      ellipsis: true,
      width: 150,
    },

    {
      title: "Model Name",
      dataIndex: "value_model_name",
      key: "value_model_name",
      align: "center",
      ellipsis: true,
      width: 150,
    },

    {
      title: "Sent Date",
      dataIndex: "sent_date",
      key: "sent_date",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (text, record) =>
        text !== undefined && text !== null && text !== ""
          ? dayjs(text).format("DD/MM/YYYY")
          : null,
    },

    {
      title: "Checked Date",
      dataIndex: "check_date",
      key: "check_date",
      align: "center",
      ellipsis: true,
      width: 200,
      render: (text, record) =>
        text !== undefined && text !== null && text !== ""
          ? dayjs(text).format("DD/MM/YYYY")
          : null,
    },

    {
      title: "Result",
      dataIndex: "pack_status",
      key: "pack_status",
      align: "center",
      ellipsis: true,
      width: 120,
      render: (text) =>
        text.toString() === "-1" ? (
          <span style={{ color: "#000000a3", fontWeight: "bold" }}>
            Đang xử lí
          </span>
        ) : text.toString() === "0" ? (
          <span style={{ color: "green", fontWeight: "bold" }}>Qualified</span>
        ) : text.toString() === "1" ? (
          <span style={{ color: "red", fontWeight: "bold" }}>
            Not Qualified
          </span>
        ) : null,
    },

    {
      title: "Details",
      dataIndex: "view_detail",
      key: "view_detail",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (text, record) => (
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            columnGap: "1.5ch",
          }}
        >
          {/* <img style={{ cursor: "pointer" }} alt='' src={DownloadIcon}></img>
          <img style={{ cursor: "pointer" }} alt='' src={UploadIcon}></img> */}
          <img
            style={{ cursor: "pointer" }}
            onClick={() => openModalDetail(record)}
            src={DetailIcon}
            alt=""
          ></img>
        </Row>
      ),
    },
  ];

  const fetchDataImage = (params = {}) => {

    const FormData = require("form-data");
    let data = new FormData();
    data.append("id_user", params.id_user);
    data.append("date_from", params.date_from);
    data.append("date_to", params.date_to);
    data.append("page_index", params.page_index);
    data.append("page_size", params.page_size);
    
    data.append("value_mfg_no", params.value_mfg_no);
    data.append("value_model_name", params.value_model_name);
    data.append("value_result", params.value_result);
    data.append("sent_date", params.sent_date);
    data.append("is_search", params.is_search);
    data.append("user_role", inforUser.user_role);

    authAxios()
      .post(`${localhost}/list_file`, data)
      .then((res) => {
        setPager({
          current: params.page_index,
          pageSize: params.page_size,
          count: res.data.total_count,
        });
        setDataSource(res.data.list_file);
        // setStatus(res.data.data_status)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const id = setInterval(() => {
      fetchDataImage({
        id_user: inforUser.user_id,
        date_from: "",
        date_to: "",
        page_index: 1,
        page_size: pager.pageSize,
        value_mfg_no: "",
        value_model_name: "",
        value_result: "",
        sent_date: "",
        is_search: false,
      });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }, []);

  const onFinish = (values) => {
    fetchDataImage({
      id_user: inforUser.user_id,
      page_index: 1,
      page_size: pager.pageSize,

      date_from: "",
      date_to: "",
      value_mfg_no: values.mfg_no !== undefined ? values.mfg_no.trim() : "",
      value_model_name:
        values.model_name !== undefined ? values.model_name.trim() : "",
      value_result: values.result || "",
      sent_date:
        values.sent_date !== undefined && values.sent_date !== null
          ? dayjs(values.sent_date).format("YYYY-MM-DD")
          : "",
      is_search: true,
    });

    setFieldFilter({
      date_from: "",
      date_to: "",
      value_mfg_no: values.mfg_no !== undefined ? values.mfg_no.trim() : null,
      value_model_name:
        values.model_name !== undefined ? values.model_name.trim() : null,
      value_result: values.result,
      sent_date:
        values.sent_date !== undefined && values.sent_date !== null
          ? dayjs(values.sent_date).format("YYYY-MM-DD")
          : null,
      is_search: true,
    });
  };

  const handleClose = () => {
    setIsOpenModalDetail(false);
  };

  const resetData = () => {
    form.resetFields();
    setFieldFilter({
      date_from: null,
      date_to: null,
      value_mfg_no: null,
      value_model_name: null,
      value_result: null,
      sent_date: null,
      is_search: false,
    });
    fetchDataImage({
      id_user: inforUser.user_id,
      date_from: "",
      date_to: "",
      page_index: 1,
      page_size: pager.pageSize,
      value_mfg_no: "",
      value_model_name: "",
      value_result: "",
      sent_date: "",
      is_search: false,
    });
  };

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = 10;
    setPager({ ...currentPager });
    fetchDataImage({
      id_user: inforUser.user_id,
      page_index: pagination.current,
      page_size: pagination.pageSize,

      date_from: "",
      date_to: "",
      value_mfg_no: fieldFilter.value_mfg_no,
      value_model_name: fieldFilter.value_model_name,
      value_result:
        fieldFilter.value_result === undefined ? "" : fieldFilter.value_result,
      sent_date: fieldFilter.sent_date,
      is_search: fieldFilter.is_search || false,
    });
  };

  return (
    <>
      <div className="element-center" style={{ padding: "1.5%" }}>
        <div
          style={{
            background: "aliceblue",
            width: "96%",
            height: "85vh",
            borderRadius: 20,
            padding: "1.5%",
            boxShadow: "2px 4px 16px -2px #00000075",
          }}
        >
          <Row className="element-center">
            <Form
              form={form}
              // initialValues={{
              //     username: username || "",
              //     password: password || "",
              // }}
              name="control-hooks"
              layout="vertical"
              onFinish={onFinish}
              style={{ width: "90%" }}
              // validateMessages={validateMessages}
            >
              <Row>
                <Col span={5}>
                  <Form.Item label="MFG No." name={"mfg_no"}>
                    <Input placeholder="Search MFG No."></Input>
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item label="Model Name" name={"model_name"}>
                    <Input placeholder="Search Model Name"></Input>
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item name={"sent_date"} label="Sent Date">
                    <DatePicker
                      format={"DD-MM-YYYY"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} offset={1}>
                  <Form.Item name={"result"} label="Result">
                    <Select
                      placeholder="Choose result"
                      style={{
                        width: "100%",
                      }}
                      key="type_document1"
                      allowClear
                      maxTagCount="responsive"
                      optionFilterProp="children"
                      mode="multiple"
                      // optionLabelProp="data-label"
                      // getPopupContainer={getPopupContainer}
                    >
                      {status.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label={" "}>
                    <Button
                      style={{ float: "right" }}
                      htmlType="submit"
                      className="btn-search"
                    >
                      <img src={SearchIcon} alt=""></img>
                    </Button>
                    <Button
                      className="btn-search"
                      style={{ marginRight: "10%", float: "right" }}
                      htmlType="button"
                      onClick={resetData}
                    >
                      <img src={ResetFields} alt=""></img>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Row>

          <Row className="element-center">
            <span style={{ fontSize: 28, fontWeight: "bold" }}>
              LIST OF MODELS
            </span>
          </Row>
          <Row className="element-center">
            <Table
              style={{ width: "90%" }}
              onChange={handleChange}
              scroll={{
                y: "55vh",
              }}
              pagination={{
                current: pager.current,
                pageSize: pager.pageSize,
                total: pager.count,
                // showQuickJumper: true,
                // locale: {
                //   jump_to: "Đến trang: ",
                //   page: "",
                // },
                // pageSizeOptions: ["10", "15", "25", "50"],
                // showSizeChanger: true,
              }}
              size="middle"
              columns={columns}
              dataSource={dataSource}
            ></Table>
          </Row>
        </div>
        {isOpenModalDetail === true ? (
          <ModalDetail
            open={isOpenModalDetail}
            close={handleClose}
            dataRecord={dataRecord}
          />
        ) : null}
      </div>
    </>
  );
}

export default Management;
