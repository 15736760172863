/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import React, { useEffect } from "react";
import { Col, DatePicker, Input, Row, Select, Table, Tag } from "antd";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Button } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { SearchOutlined } from "@ant-design/icons";
import ModalCheckSheets from "./Modal/ModalCheckSheets";
import PropTypes from "prop-types";
import ModalDaily from "./Modal/ModalDaily";
import ModalDailyAverage from "./Modal/ModalDailyAverage";
import dayjs from "dayjs";

ChartJS.register(ArcElement, Tooltip, Legend);

const screenHeight = window.innerHeight;

const dataColor = [
  {
    id: 1,
    name: "Qualified",
    color: "#329B00",
  },
  {
    id: 2,
    name: "Not Qualified",
    color: "#B92121",
  },
  {
    id: 3,
    name: "Image Not Good",
    color: "#FF8C00",
  },
  {
    id: 4,
    name: "Processing",
    color: "#000000",
  },
];

const ManagementDashboard = ({
  isOpenModalChecksheets,
  handleClickOpenModalChecksheets,
  isOpenModalDailyLC,
  handleClickOpenModalDailyLC,
  isOpenModalDailyAverage,
  handleClickOpenModalDailyAverage,
  isOpenTable,
  listPackageAll,
  isValueDashBoard,
  isValueCheckSheets,
  isValueDailyNotqualified,
  isValueDailyNG,
  isValueDailyAll,
  isValueDailyAverage,
  handleClickButtonOpenTable,
  handleDatePickerModalCheckSheet,
  handleDatePickerModalDaily,
  handleDatePickerModalDailyAverage,
  handleDatePickerManagement,
  handleSearchTableManagemant,
  valueSearch,
  checkValueSearch,
  handleChangeResult,
  handleClickSearch,
  handleChangeStatus,
  checkClickSearch,
  checkValueStatus,
  checkValueResult,
  isValueAverageTimeLc,
}) => {
  const currentDate = dayjs();

  const allValuesZero =
    parseInt(
      isValueDashBoard.stt_processing === "[]"
        ? 0
        : isValueDashBoard.stt_processing
    ) === 0 &&
    parseInt(
      isValueDashBoard.stt_complete === "[]" ? 0 : isValueDashBoard.stt_complete
    ) === 0 &&
    parseInt(
      isValueDashBoard.stt_verified === "[]" ? 0 : isValueDashBoard.stt_verified
    ) === 0;

  const data = {
    labels: ["Processing", "Completed", "Verified"],
    datasets: [
      {
        data: [
          isValueDashBoard.stt_processing === "[]"
            ? 0
            : isValueDashBoard.stt_processing,
          isValueDashBoard.stt_complete === "[]"
            ? 0
            : isValueDashBoard.stt_complete,
          isValueDashBoard.stt_verified === "[]"
            ? 0
            : isValueDashBoard.stt_verified,
          allValuesZero === true ? 1 : 0,
        ],
        backgroundColor: [
          "#626262",
          "#085FA0",
          "#577354",
          "rgb(121 121 121 / 34%)",
        ],
        // hoverOffset: 4,
      },
    ],
  };

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw: (chart) => {
      if (chart.options?.plugins?.centerText?.display) {
        const { width, height, ctx } = chart;
        ctx.restore();
        const fontSize = (height / 220).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";

        const text = `${
          parseInt(
            isValueDashBoard.stt_processing === "[]"
              ? 0
              : isValueDashBoard.stt_processing
          ) +
          parseInt(
            isValueDashBoard.stt_complete === "[]"
              ? 0
              : isValueDashBoard.stt_complete
          ) +
          parseInt(
            isValueDashBoard.stt_verified === "[]"
              ? 0
              : isValueDashBoard.stt_verified
          )
        } Checksheets`;
        const lines = text.split(" ");
        const lineHeight = fontSize * 16;

        const textY = (height - lines.length * lineHeight) / 2;

        lines.forEach((line, index) => {
          ctx.fillText(line, width / 2, textY + index * lineHeight);
        });

        ctx.save();
      }
    },
  };

  if (isValueDashBoard.stt_processing !== undefined) {
    ChartJS.register(centerTextPlugin);
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Tắt hiển thị tên và màu
      },
      centerText: {
        display: true, // Set to false to not display the center text
      },
      tooltip: {
        enabled: !allValuesZero, // Vô hiệu hóa tooltips
      },
    },
    centerText: true,
  };

  const columns = [
    {
      title: "Package Name",
      dataIndex: "pack_name",
      key: "pack_name",
    },
    {
      title: "MFG No.",
      dataIndex: "vl_mfg_no",
      key: "vl_mfg_no",
    },
    {
      title: "Model Name",
      dataIndex: "vl_model_name",
      key: "vl_model_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      align: "center",
      render: (text) =>
        dataColor.map((tag) => {
          if (tag.name === text) {
            return (
              <span style={{ color: tag.color }} key={tag.id}>
                {text}
              </span>
            );
          }
          return null;
        }),
    },
    {
      title: "Uploaded By",
      dataIndex: "upload_usname",
      key: "upload_usname",
      align: "center",
    },
    {
      title: "Upload Date",
      dataIndex: "upload_date",
      key: "upload_date",
      align: "center",
    },
  ];

  return (
    <Row style={{ marginTop: 30 }}>
      <Col span={11}>
        <div
          style={{ display: "flex", justifyContent: "center", width: "80%" }}
        >
          <DatePicker
            format={"DD-MM-YYYY"}
            defaultValue={currentDate}
            allowClear={false}
            disabled={true}
            onChange={handleDatePickerManagement}
            className="management-dashboard-date-picker"
          />
        </div>
        <Row>
          <Col span={14} style={{ height: 200, marginTop: 30 }}>
            <div style={{ float: "right", height: "100%" }}>
              <Doughnut
                data={data}
                options={options}
                style={{ float: "right" }}
              />
            </div>
          </Col>
          <Col span={1}></Col>
          <Col span={9} style={{ margin: "auto" }}>
            <div style={{ display: "flex" }}>
              <Tag color="#626262" style={{ width: 40, height: 20 }}></Tag>
              <span
                className="management-dashboard-span-status"
                data-status-color-processing={true}
              >
                Processing
              </span>
              <span
                className="management-dashboard-span-total"
                data-total-color-processing={true}
              >
                {isValueDashBoard.stt_processing === "[]"
                  ? 0
                  : isValueDashBoard.stt_processing}
              </span>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <Tag color="#085FA0" style={{ width: 40, height: 20 }}></Tag>
              <span
                className="management-dashboard-span-status"
                data-status-color-completed={true}
              >
                Completed
              </span>
              <span
                className="management-dashboard-span-total"
                data-total-color-completed={true}
              >
                {isValueDashBoard.stt_complete === "[]"
                  ? 0
                  : isValueDashBoard.stt_complete}
              </span>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <Tag color="#577354" style={{ width: 40, height: 20 }}></Tag>
              <span
                className="management-dashboard-span-status"
                data-status-color-verified={true}
              >
                Verified
              </span>
              <span
                className="management-dashboard-span-total"
                data-total-color-verified={true}
              >
                {isValueDashBoard.stt_verified === "[]"
                  ? 0
                  : isValueDashBoard.stt_verified}
              </span>
            </div>
          </Col>
        </Row>
        <Button
          className="management-dashboard-button-view"
          data-check-float={true}
          onClick={handleClickOpenModalChecksheets}
        >
          View statistics for last 30 days
        </Button>
      </Col>
      <Col span={11} offset={2}>
        <Row style={{ display: "flex", height: "100%" }}>
          <Col span={11} style={{ margin: "auto" }}>
            <span
              style={{
                display: "flex",
                fontWeight: 500,
              }}
            >
              Quality of Last Check:
            </span>
            <div className="management-dashboard-div-ratio">
              <span className="management-dashboard-span-ratio">
                Ratio of Non-Qualified
              </span>
              <span
                className="management-dashboard-span-ratio"
                data-check-percent={true}
              >
                {isValueDashBoard.ratio_not_qualified}%
              </span>
            </div>
            <div
              className="management-dashboard-div-ratio"
              data-color-ratio={true}
            >
              <span className="management-dashboard-span-ratio">
                Ratio of Images Not Good
              </span>
              <span
                className="management-dashboard-span-ratio"
                data-check-percent={true}
              >
                {isValueDashBoard.ratio_notgood}%
              </span>
            </div>
          </Col>
          <Col span={13}></Col>
          <Col span={11}></Col>
          <Col span={13}>
            <Button
              className="management-dashboard-button-view"
              data-check-margin-top={true}
              onClick={handleClickOpenModalDailyLC}
            >
              View statistics for last 30 days
            </Button>
          </Col>
          <Col
            span={11}
            style={{ margin: "auto" }}
            className="management-dashboard-row-col-right"
          >
            <span
              style={{
                display: "flex",
                fontWeight: 500,
              }}
            >
              Average time of last check per LC Order:
            </span>
          </Col>
          <Col span={13} className="management-dashboard-row-col-right">
            <span
              style={{
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {isValueAverageTimeLc} mins
            </span>
          </Col>
          <Col span={11}></Col>
          <Col span={13}>
            <Button
              className="management-dashboard-button-view"
              data-check-margin-top={true}
              onClick={handleClickOpenModalDailyAverage}
            >
              View statistics for last 30 days
            </Button>
          </Col>
        </Row>
      </Col>
      <Row style={{ width: "98%", margin: "auto", marginTop: "50px" }}>
        <Col
          span={10}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            endIcon={
              isOpenTable === false ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )
            }
            onClick={handleClickButtonOpenTable}
            style={{ fontSize: 16, fontWeight: 400 }}
          >
            View Lists of LC Order
          </Button>
        </Col>
        <Col span={14}></Col>
        <Col span={10}></Col>
        <Col span={14}>
          {isOpenTable === true && (
            <Row style={{ width: "100%" }}>
              <Col span={6}></Col>
              <Col span={4}>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder="Status"
                  value={checkValueStatus}
                  onChange={handleChangeStatus}
                  allowClear
                  options={[
                    {
                      value: "Processing",
                      label: "Processing",
                    },
                    {
                      value: "Complete",
                      label: "Complete",
                    },
                    {
                      value: "Verified",
                      label: "Verified",
                    },
                  ]}
                />
              </Col>
              <Col span={4} offset={1}>
                <Select
                  style={{
                    width: "100%",
                  }}
                  value={checkValueResult}
                  placeholder="Result"
                  onChange={handleChangeResult}
                  allowClear
                  options={[
                    {
                      value: "Processing",
                      label: "Processing",
                    },
                    {
                      value: "Qualified",
                      label: "Qualified",
                    },
                    {
                      value: "Not Qualified",
                      label: "Not Qualified",
                    },
                    {
                      value: "Image Not Good",
                      label: "Image Not Good",
                    },
                  ]}
                />
              </Col>
              <Col span={5} offset={1}>
                <Input
                  placeholder="Search MFG No, Model Name"
                  suffix={<SearchOutlined />}
                  value={checkValueSearch}
                  onChange={handleSearchTableManagemant}
                  className="management-dashboard-input-search"
                />
              </Col>
              <Col span={2} offset={1}>
                <Button variant="outlined" onClick={handleClickSearch}>
                  Search
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {isOpenTable === true && (
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={
              checkClickSearch === true ? valueSearch : listPackageAll
            }
            scroll={{
              y: screenHeight - 630,
            }}
            size="small"
            className="management-dashboard-table"
          />
        </Col>
      )}

      <ModalCheckSheets
        isOpenModalChecksheets={isOpenModalChecksheets}
        handleClickOpenModalChecksheets={handleClickOpenModalChecksheets}
        isValueCheckSheets={isValueCheckSheets}
        handleDatePickerModalCheckSheet={handleDatePickerModalCheckSheet}
      />

      <ModalDaily
        isOpenModalDailyLC={isOpenModalDailyLC}
        handleClickOpenModalDailyLC={handleClickOpenModalDailyLC}
        isValueDailyNotqualified={isValueDailyNotqualified}
        isValueDailyNG={isValueDailyNG}
        isValueDailyAll={isValueDailyAll}
        handleDatePickerModalDaily={handleDatePickerModalDaily}
      />

      <ModalDailyAverage
        isOpenModalDailyAverage={isOpenModalDailyAverage}
        handleClickOpenModalDailyAverage={handleClickOpenModalDailyAverage}
        isValueDailyAverage={isValueDailyAverage}
        handleDatePickerModalDailyAverage={handleDatePickerModalDailyAverage}
      />
    </Row>
  );
};

ManagementDashboard.propTypes = {
  isOpenModalChecksheets: PropTypes.bool,
  handleClickOpenModalChecksheets: PropTypes.func,
  isOpenModalDailyLC: PropTypes.bool,
  handleClickOpenModalDailyLC: PropTypes.func,
  isOpenModalDailyAverage: PropTypes.bool,
  handleClickOpenModalDailyAverage: PropTypes.func,
  isOpenTable: PropTypes.bool,
  listPackageAll: PropTypes.array,
  isValueDashBoard: PropTypes.any,
  isValueCheckSheets: PropTypes.array,
  isValueDailyNotqualified: PropTypes.array,
  isValueDailyNG: PropTypes.array,
  isValueDailyAll: PropTypes.array,
  isValueDailyAverage: PropTypes.array,
  handleClickButtonOpenTable: PropTypes.func,
  handleDatePickerModalCheckSheet: PropTypes.func,
  handleDatePickerModalDaily: PropTypes.func,
  handleDatePickerModalDailyAverage: PropTypes.func,
  handleDatePickerManagement: PropTypes.func,
  handleSearchTableManagemant: PropTypes.func,
  valueSearch: PropTypes.array,
  checkValueSearch: PropTypes.any,
  handleChangeResult: PropTypes.func,
  handleClickSearch: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  checkClickSearch: PropTypes.any,
  checkValueStatus: PropTypes.any,
  checkValueResult: PropTypes.any,
};

export default ManagementDashboard;
