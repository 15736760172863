/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./formSelect2.css";

import logoIwaki from "../../images/LogoIwaki.svg";
import BackgroundIconVector from "../../images/BackgroundIconVector.svg";
import axios from "axios";
import {
  Avatar,
  Col,
  Modal,
  Row,
  Select,
  Upload,
  message,
  notification,
} from "antd";
import { localhost } from "../../server";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LogoutIcon from "@mui/icons-material/Logout";
import { Menu, MenuItem } from "@mui/material";
import iconUpload from "../../images/iconUpload2.svg";
import iconImportant from "../../images/iconPriority2.svg";
import iconDelete from "../../images/iconDelete2.svg";
import iconStartCheck from "../../images/iconStarCheck.svg";
import iconClose from "../../images/iconClose.svg";
import iconSuccess from "../../images/iconComplete.svg";
import iconArrowDropDown from "../../images/arrowDropDown.svg";
import iconLoading from "../../images/iconLoading.svg";
import iconAddItem from "../../images/iconAddItem.svg";

import ModalCheckImage from "./modalUpload/ModalCheckImage";
import "./modalUpload/ModelSelect.css";
import fileLanguage from "../../language.json";
import { optionLanguage } from "../../data";
import SelectPumpType from "../management_of_user/Modal";
import { authAxios } from "../../api/axiosClient";

const MySwal = withReactContent(Swal);
const { Option } = Select;
export default function FormSelect2() {
  const [isModalImageVisible, setIsModalImageVisible] = useState(false);
  const [isModalDeleteImage, setIsModalDeleteImage] = useState(false);
  const [isPrioritize, setIsPrioritize] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState(
    JSON.parse(sessionStorage.getItem("OptionMachine"))
  );
  const [canUpload, setCanUpload] = useState(false);
  const [isChoose, setIsChoose] = useState(false);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [checkedTime, setCheckedTime] = useState(null);
  const [showModalSelectPumpType, setShowModalSelectPumpType] = useState(false);

  // const userInfo = useSelector((state) => state.user);
  const [chooseLanguage, setChooseLanguage] = useState(
    sessionStorage.getItem("choosedLanguage") !== null &&
      sessionStorage.getItem("choosedLanguage") !== undefined
      ? sessionStorage.getItem("choosedLanguage")
      : "english"
  );
  const role = JSON.parse(sessionStorage.getItem("info_user")).user_role


  useEffect(() => {

    // const FormData = require("form-data");
    // let data = new FormData();
    // data.append("user_role", role);
    authAxios()
      .get(`${localhost}/get_list_pump`,
        {
          params: {
            user_role: role
          },
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then((response) => {
        setItems(response.data.list_pumb);
        const checkItem = sessionStorage.getItem("OptionMachine");
        if (checkItem !== null && checkItem !== undefined) {
          setItems2(JSON.parse(sessionStorage.getItem("OptionMachine")));
        } else {
          setItems2(response.data.list_pumb[0]);
          sessionStorage.setItem(
            "OptionMachine",
            JSON.stringify(response.data.list_pumb[0])
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setItems2]);

  useEffect(() => {
    if (checkedTime === true) {
      setCheckedTime(null);
      MySwal.fire({
        iconHtml: <img src={iconSuccess} alt="" />,
        title: fileLanguage[chooseLanguage].completed,
        customClass: "custome-success",
        showConfirmButton: false,
        timer: 2500,
        customClass: {
          popup: "custome-form-success",
          icon: "custome-class-success",
          title: "custome-title-success",
        },
      });
      setImageList([]);
      setIsModalImageVisible(false);
      setIsPrioritize(false);
    } else if (checkedTime === false) {
      setCheckedTime(null);
      MySwal.fire({
        iconHtml: <img src={iconLoading} alt="" />,
        title: fileLanguage[chooseLanguage].uploading_all,
        showConfirmButton: false,
        timer: 2000000,
        allowOutsideClick: false,
        customClass: {
          popup: "custome-form-loading",
          icon: "custome-class-loading",
          title: "custome-title-loading",
        },
      });
    }
  }, [checkedTime]);

  useEffect(() => {
    if (imageList.length === 0) {
      setIsModalImageVisible(false);
      setIsPrioritize(false);
      setIsChoose(false);
      // onClickCancelCheckImage();
    }
  }, [imageList]);

  const handleCancel = () => {
    setIsModalImageVisible(false);
    setIsPrioritize(false);
    setIsChoose(false);
    onClickCancelCheckImage();
    setImageList([]);
  };

  const showModalDelete = () => {
    setIsModalDeleteImage(true);
  };

  const handleDeleteCancel = () => {
    setIsModalDeleteImage(false);
  };

  const onClickCheckImage = (index) => {
    if (isChoose) {
      const updatedImages = imageList.map((image, idx) =>
        idx === index
          ? { ...image, imageCheck: !image.imageCheck }
          : { ...image }
      );
      setImageList(updatedImages);
    }
  };

  const onClickCancelCheckImage = () => {
    const updatedImages = imageList.map((image) =>
      image.imageCheck === true ? { ...image, imageCheck: false } : { ...image }
    );
    setImageList(updatedImages);
  };

  const countCheckedImages = () => {
    return imageList.filter((image) => image.imageCheck).length;
  };

  const handleDeleteImagesOk = () => {
    const updatedImages = imageList.filter((image) => !image.imageCheck);
    setImageList(updatedImages);
    setIsModalDeleteImage(false);
    if (imageList.length === 0) {
      setIsModalImageVisible(false);
    }
  };
  const handleDeleteImages = () => {
    showModalDelete();
  };
  const buttonPrioritize = () => {
    setIsPrioritize(!isPrioritize);
  };

  const handleChangeSelectOptions = (value, key) => {
    const myObject = {
      pumb_id: key.key,
      pumb_model: key.value,
      is_multi: key.is_multi,
      lv1_fields: key.lv1_fields,
      lv3_fields: key.lv3_fields,
      lv1_others: key.lv1_others,
      lv3_others: key.lv3_others,
    };
    setItems2(myObject);
    sessionStorage.setItem("OptionMachine", JSON.stringify(myObject));
  };
  const handleChangeSelectLanguage = (value) => {
    setChooseLanguage(value);
    sessionStorage.setItem("choosedLanguage", value);
  };

  useEffect(() => {
    const getLanguage = sessionStorage.getItem("choosedLanguage");
    if (getLanguage !== null && getLanguage !== undefined) {
      setChooseLanguage(getLanguage);
    } else {
      setChooseLanguage("english");
      sessionStorage.setItem("choosedLanguage", "english");
    }
  }, []);

  useEffect(() => {
    const getOption = sessionStorage.getItem("OptionMachine");
    if (getOption === null) {
      setCanUpload(true);
    } else {
      setCanUpload(false);
    }
  }, [setCanUpload, sessionStorage.getItem("OptionMachine")]);

  const onClickCheckUpload = () => {
    if (canUpload === true) {
      notification.destroy();
      notification.error({
        message: fileLanguage[chooseLanguage].please_select_the_machine_code,
        // description: "Vui lòng chọn mã máy",
        placement: "topRight",
      });
    }
  };

  const uploadProps = {
    name: "file",
    showUploadList: false,
    capture: false,
    multiple: true,
    beforeUpload(file) {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isTIF = file.type === "image/tif";

      if (!isPNG && !isJPG && !isJPEG && !isTIF) {
        message.error(`${file.name} is not a Image file`);
      } else {
        return isPNG || isJPEG || isJPG || isTIF;
      }
    },
    onChange(info) {
      const newFile = info.file.originFileObj;
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageBase64 = e.target.result;
        const imageInfo = {
          imageName: newFile.name,
          imageType: newFile.type,
          imageBase64: imageBase64,
          imageCheck: false,
        };
        setImageList((prevImageList) => [...prevImageList, imageInfo]);
      };
      reader.readAsDataURL(newFile);
    },
  };
  const customRequest = () => {
    setTimeout(() => {
      setIsModalImageVisible(true);
    }, 1000);
  };

  const [isModalQuestion, setIsModalQuestion] = useState(false);
  const closeModalQuestion = () => {
    setIsModalQuestion(false);
  };
  const showModalCheckImage = () => {
    if (imageList.length > 3) {
      setShowModalUpload(false);
      setIsModalQuestion(true);
    } else if (imageList.length < 3) {
      setShowModalUpload(false);
      setIsModalQuestion(true);
    } else {
      multiUploadImage();
    }
  };
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  const multiUploadImage = async () => {
    setIsModalQuestion(false);
    setShowModalUpload(false);
    const userInfoId = sessionStorage.getItem("userId");
    try {
      if (items2 !== null) {
        setCheckedTime(false);
        const prioriti = isPrioritize ? "1" : "0";
        const FormData = require("form-data");

        let data = new FormData();
        data.append("prioriti", prioriti);
        data.append("id_user", userInfoId);
        data.append("type_upload", "2");
        data.append("pumb_model", items2.pumb_model);
        data.append("pumb_id", items2.pumb_id);
        data.append("user_role", role);

        const promises = imageList.map((image) => {
          const nameFile = image.imageName;
          const typeFile = image.imageType;
          const getFileBase64 = image.imageBase64;
          const checkFileSize =
            parseInt(getFileBase64.replace(/=/g, "").length * 0.75) /
            1024 /
            1024;
          let scaleFactor;
          let quality;
          if (checkFileSize < 5) {
            scaleFactor = 1;
            quality = 1;
          } else if (5 <= checkFileSize && checkFileSize < 10) {
            scaleFactor = 0.5;
            quality = 0.8;
          } else if (10 <= checkFileSize && checkFileSize < 20) {
            scaleFactor = 0.55;
            quality = 0.8;
          } else if (checkFileSize >= 20) {
            scaleFactor = 0.6;
            quality = 0.8;
          }

          return loadImage(getFileBase64)
            .then((img) => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              const newWidth = img.width * scaleFactor;
              const newHeight = img.height * scaleFactor;

              canvas.width = newWidth;
              canvas.height = newHeight;
              ctx.drawImage(img, 0, 0, newWidth, newHeight);
              const resizedImageBase64 = canvas.toDataURL(
                "image/jpeg",
                quality
              );
              // console.log(
              //   parseInt(resizedImageBase64.replace(/=/g, "").length * 0.75)
              // );

              const byteCharacters = atob(resizedImageBase64.split(",")[1]);

              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);

              const buffer = byteArray.buffer;

              const blob = new Blob([buffer], { type: typeFile });

              const getFile = new File([blob], nameFile, { type: typeFile });

              data.append("file_upload", getFile);
            })
            .catch((error) => {
              console.error("Error loading image:", error);
            });
        });

        await Promise.all(promises);

        await authAxios().post(`${localhost}/upload_file`, data);
        setCheckedTime(true);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const clickChooseImageDelete = () => {
    setIsChoose(!isChoose);
    if (isChoose) {
      setIsChoose(false);
      onClickCancelCheckImage();
    } else {
      setIsChoose(true);
    }
  };

  const openModalUpload = () => {
    setShowModalUpload(true);
  };
  const closeModalUpload = () => {
    setShowModalUpload(false);
  };

  const checkMachineBeforeChangeCamera = async () => {
    const callMachine = sessionStorage.getItem("OptionMachine");
    if (callMachine === null || callMachine === undefined) {
      notification.destroy();
      notification.error({
        message: fileLanguage[chooseLanguage].please_select_the_machine_code,
        // description: "Vui lòng chọn mã máy",
        placement: "topRight",
      });
    } else {
      window.location.href = "/CaptureCamera";
    }
  };

  const showModalManage = () => {
    if (canUpload === true) {
      notification.destroy();
      notification.error({
        message: fileLanguage[chooseLanguage].please_select_the_machine_code,
        // description: "Vui lòng chọn mã máy",
        placement: "topRight",
      });
    } else {
      window.location.href = "/";
    }
  };

  window.addEventListener("beforeunload", function (event) { });

  const [isAnchorEl, setIsAnchorEl] = useState(null);
  const open = Boolean(isAnchorEl);

  const handleClickPerson = (event) => {
    setIsAnchorEl(event.currentTarget);
  };

  const handleClosePerson = () => {
    setIsAnchorEl(null);
  };

  const logout_new = (e) => {
    sessionStorage.clear()
    // sessionStorage.removeItem("Status_Login");
    window.location = "/";
  };

  useEffect(() => {
    // window.navigator.vibrate(500)
    function handleVisibilityChange() {
      // Check if the page is visible
      if (document.visibilityState === "visible") {
        // Check if the Vibration API is supported
        if ("vibrate" in navigator) {
          // Vibrate for 200 milliseconds when the page becomes visible
          navigator.vibrate(200);
        } else {
          console.log("Vibration API not supported");
        }
      }
    }

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);
  }, []);

  const customUpload = () => {
    // Hàm giả lập không làm gì cả
    console.log("Bạn không thể tải lên khi disabled");
    return new Promise((resolve, reject) => {
      reject("Không thể tải lên khi disabled");
    });
  };

  const handleCancelModalSelectPumpType = () => {
    setShowModalSelectPumpType(true);
  };

  return (
    <>
      <div className="FormSelect">
        <div className="logoIwakiSelect">
          <Row style={{ height: "18svh", maxHeight: "18svh" }}>
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                className="imgLogoIwaki"
                style={{ borderRadius: "0" }}
                src={logoIwaki}
                alt=""
              />
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                value={chooseLanguage}
                onChange={handleChangeSelectLanguage}
                className="optionLanguage"
                suffixIcon={
                  <span className="spanSuffixIconDropdownLanguage">
                    <img
                      style={{ overflow: "unset" }}
                      className="iconDropDownLanguage"
                      src={iconArrowDropDown}
                      alt=""
                    />
                  </span>
                }
              >
                {optionLanguage.map((item) => (
                  <Option
                    className="optionLanguageItem"
                    key={item.value}
                    value={item.value}
                  >
                    <span className="spanOptionLanguageItem">
                      {item.icon}
                      {item.label}
                    </span>
                  </Option>
                ))}
              </Select>
              <Avatar
                onClick={handleClickPerson}
                style={{
                  backgroundColor: "rgba(5, 141, 244, 0.1)",
                  color: "#058DF4",
                  marginLeft: "20px",
                }}
              >
                {JSON.parse(
                  sessionStorage.getItem("info_user")
                ).user_fullname.substr(0, 1)}
              </Avatar>
              <Menu
                id="basic-menu-formSelect"
                anchorEl={isAnchorEl}
                open={open}
                style={{ width: "85px", top: 8 }}
                onClose={handleClosePerson}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  style={{ width: "100%" }}
                  className={"menu-user"}
                  onClick={() => logout_new()}
                >
                  <LogoutIcon style={{ marginRight: "5%" }} />
                  Log out
                </MenuItem>
              </Menu>
            </Col>
          </Row>
        </div>
        <div className="button-select">
          <Row style={{ width: "100%" }}>
            <button
              onClick={handleCancelModalSelectPumpType}
              className="buttonSelect btnSelectManager"
            >
              <p className="textCapture">pump type</p>
            </button>
          </Row>
          <Row style={{ width: "100%" }}>
            <Upload
              disabled={canUpload}
              className="btnUploadSelect"
              {...uploadProps}
              fileList={[]}
              listType="picture"
              accept=".png,.jpg,.jpeg,.tif"
              customRequest={customRequest}
              method="null"
            >
              <button
                onClick={onClickCheckUpload}
                type="button"
                className="buttonSelect btnSelectUpload"
              >
                <p className="textUpload">
                  {fileLanguage[chooseLanguage].upload_file}
                </p>
              </button>
            </Upload>
            <Modal
              className="ModalViewImageSelect"
              open={isModalImageVisible}
              closable={false}
              footer={null}
              style={{ top: "30px" }}
            >
              <div className="HeaderModalSelect">
                <Row style={{ height: "5svh" }}>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="buttonCloseModalView"
                      onClick={handleCancel}
                    >
                      <img
                        alt="iconCLose"
                        className="iconCloseSelect"
                        src={iconClose}
                      />
                    </button>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {countCheckedImages() > 0 ? (
                      <span className="spanTitleHeader">
                        {fileLanguage[chooseLanguage].image_selected.replace(
                          "†",
                          countCheckedImages()
                        )}
                      </span>
                    ) : (
                      <span className="spanTitleHeader">
                        {fileLanguage[chooseLanguage].captured_image}
                      </span>
                    )}
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    {isChoose ? (
                      <button
                        className="btnChoose"
                        onClick={clickChooseImageDelete}
                      >
                        <span className="buttonChoose">
                          {fileLanguage[chooseLanguage].cancel}
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btnChoose"
                        onClick={clickChooseImageDelete}
                      >
                        <span className="buttonChoose">
                          {fileLanguage[chooseLanguage].select}
                        </span>
                      </button>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="content-image">
                <div className="imageGallery">
                  {imageList.map((image, index) => (
                    <div
                      key={index}
                      className="image-item"
                      onClick={() => onClickCheckImage(index)}
                    >
                      <img
                        className="imageSourceGalley"
                        src={image.imageBase64}
                        alt={image.imageName}
                      />
                      {isChoose ? (
                        <input
                          className="inputChooseSelect"
                          checked={image.imageCheck}
                          alt={image.imageName}
                          type="radio"
                          onChange={() => { }}
                        />
                      ) : null}
                    </div>
                  ))}
                  <Upload
                    disabled={canUpload}
                    className="btnUploadImport"
                    {...uploadProps}
                    fileList={[]}
                    listType="picture"
                    accept=".png,.jpg,.jpeg,.tif"
                    customRequest={customUpload}
                  >
                    <img
                      src={iconAddItem}
                      onClick={onClickCheckUpload}
                      className="buttonImport"
                      alt=""
                    />
                  </Upload>
                </div>
              </div>
              <div className="FooterDeleteImage">
                {isPrioritize ? (
                  <button className="buttonStarCheck">
                    <img src={iconStartCheck} alt="" />
                    <span className="textButtonStarCheck">
                      {fileLanguage[chooseLanguage].given_priority}
                    </span>
                  </button>
                ) : countCheckedImages() > 0 ? (
                  <>
                    <button
                      onClick={handleDeleteImages}
                      className="buttonDeleteImage"
                    >
                      <img src={iconDelete} alt="" />
                      <span className="textButtonDelete">
                        {fileLanguage[chooseLanguage].delete_image.replace(
                          "†",
                          countCheckedImages()
                        )}
                      </span>
                    </button>
                    <Modal
                      className="ModalDeleteImageSelect"
                      open={isModalDeleteImage}
                      closable={false}
                      footer={null}
                    >
                      <div className="TitleDeleteImage">
                        <span>
                          {fileLanguage[chooseLanguage].confirm_delete_photo}
                        </span>
                      </div>
                      <div className="ButtonDeleteModal">
                        <button
                          className="ButtonDeleteAll"
                          onClick={() =>
                            handleDeleteImagesOk(imageList, setImageList)
                          }
                        >
                          {fileLanguage[chooseLanguage].yes}
                        </button>
                        <button
                          className="ButtonDeleteAll"
                          onClick={handleDeleteCancel}
                        >
                          {fileLanguage[chooseLanguage].no}
                        </button>
                      </div>
                    </Modal>
                  </>
                ) : null}
              </div>
              <div className="footerModal">
                <div className="divUploadBtn">
                  {isChoose ? (
                    <></>
                  ) : (
                    <button className="uploadButton" onClick={openModalUpload}>
                      <img className="button-upload" src={iconUpload} alt="" />
                    </button>
                  )}
                  <Modal
                    className="ModelUploadImage"
                    open={showModalUpload}
                    closable={false}
                    footer={null}
                  >
                    <div className="TitleUpdateModal">
                      <span>
                        {fileLanguage[chooseLanguage].confirm_upload_photo}
                      </span>
                    </div>
                    <div className="ButtonUpdateModal">
                      <button
                        className="ButtonUpdateAll"
                        onClick={showModalCheckImage}
                      >
                        {fileLanguage[chooseLanguage].yes}
                      </button>
                      <button
                        className="ButtonUpdateAll"
                        onClick={closeModalUpload}
                      >
                        {fileLanguage[chooseLanguage].no}
                      </button>
                    </div>
                  </Modal>
                </div>
                <div className="divCheckPrioritize">
                  <button onClick={buttonPrioritize} className="checkBoxButton">
                    <img
                      className="button-prioriti"
                      src={iconImportant}
                      alt=""
                    />
                  </button>
                  <span
                    className="spanTextPrioritize"
                    style={{
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "22px",
                      letterSpacing: "-0.40799999237060547px",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    {fileLanguage[chooseLanguage].priority}
                  </span>
                </div>
              </div>
            </Modal>
          </Row>
          <Row style={{ width: "100%" }}>
            <button
              className="buttonSelect btnSelectManager"
              onClick={showModalManage}
            >
              <p className="textFileManager">
                {fileLanguage[chooseLanguage].file_manager}
              </p>
            </button>
          </Row>
        </div>
        <div className="imageNenVectorFormSelect">
          <img src={BackgroundIconVector} alt="" />
        </div>
        {isModalQuestion ? (
          <ModalCheckImage
            showModalQuestion={isModalQuestion}
            messageBox={
              imageList.length > 3
                ? fileLanguage[chooseLanguage].more_than_2_photo
                : imageList.length < 3
                  ? fileLanguage[chooseLanguage].less_than_2_photo
                  : null
            }
            multiUploadImages={multiUploadImage}
            closeModalUpload={closeModalQuestion}
            spanYes={fileLanguage[chooseLanguage].yes}
            spanNo={fileLanguage[chooseLanguage].no}
          />
        ) : null}
      </div>
      <SelectPumpType
        showModalSelectPumpType={showModalSelectPumpType}
        setShowModalSelectPumpType={setShowModalSelectPumpType}
        handleChangeSelectOptions={handleChangeSelectOptions}
        fileLanguage={fileLanguage}
        chooseLanguage={chooseLanguage}
        items={items}
        items2={items2}
        iconArrowDropDown={iconArrowDropDown}
        checkMachineBeforeChangeCamera={checkMachineBeforeChangeCamera}
      />
    </>
  );
}
